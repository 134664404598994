import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';
import { StaffMember } from '../../data/staffmember';
import { StaffmemberService} from '../../staffmember.service';

@Component({
  selector: 'app-adm-staff',
  templateUrl: './adm-staff.component.html',
  styleUrls: ['./adm-staff.component.css']
})
export class AdmStaffComponent implements OnInit {
  staffmembers:StaffMember[];
  isLoggedIn:boolean;
  newMember:StaffMember;

  constructor(
    private currentUser:UserService,
    private messageService: MessageService,
    private router: Router,
    private staffService: StaffmemberService
  ) {
    this.newMember=new StaffMember;
  }

  ngOnInit() : void {
    this.isLoggedIn=this.currentUser.isUserLoggedIn();
    //this.checkUser();
    this.getStaffMembers();
  }
  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }
  goBack(){
    this.router.navigateByUrl('/beheer');
  }
  goTo(url){
    this.router.navigateByUrl(url);
  }
  getStaffMembers():void{
    this.log('getting staff members');
    this.staffService.getStaff().subscribe(staffmembers => this.staffmembers = staffmembers);
  }
  newStaffMember(): void {
    this.staffService.newStaffmember(this.newMember).subscribe(newMember => this.goTo('/beheer/staff/'+newMember.id));
  }
  private log(message: string) {
    this.messageService.add(`BeheerStaffComponent: ${message}`);
  }
}
