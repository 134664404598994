import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AdmBlock, AdmShowList } from '../adm-block';
import { AdmBlockService} from '../adm-block.service';
import { Location } from '@angular/common';
import { Day } from '../enums/day';

@Component({
  selector: 'app-adm-block',
  templateUrl: './adm-block.component.html',
  styleUrls: ['./adm-block.component.css']
})
export class AdmBlockComponent implements OnInit {
  isLoggedIn:boolean;
  block:AdmBlock;
  shows:AdmShowList[];
  accesstoken:string;
  options = [
    { name: Day[Day.Maandag], value: Day.Maandag },
    { name: Day[Day.Dinsdag], value: Day.Dinsdag },
    { name: Day[Day.Woensdag], value: Day.Woensdag },
    { name: Day[Day.Donderdag], value: Day.Donderdag },
    { name: Day[Day.Vrijdag], value: Day.Vrijdag },
    { name: Day[Day.Zaterdag], value: Day.Zaterdag },
    { name: Day[Day.Zondag], value: Day.Zondag },
  ]
  hours = [
    { name: '8:00 - 9:00', value: 8 },
    { name: '9:00 - 10:00', value: 9 },
    { name: '10:00 - 11:00', value: 10 },
    { name: '11:00 - 12:00', value: 11 },
    { name: '12:00 - 13:00', value: 12 },
    { name: '13:00 - 14:00', value: 13 },
    { name: '14:00 - 15:00', value: 14 },
    { name: '15:00 - 16:00', value: 15 },
    { name: '16:00 - 17:00', value: 16 },
    { name: '17:00 - 18:00', value: 17 },
    { name: '18:00 - 19:00', value: 18 },
    { name: '19:00 - 20:00', value: 19 },
    { name: '20:00 - 21:00', value: 20 },
    { name: '21:00 - 22:00', value: 21 },
    { name: '22:00 - 23:00', value: 22 },
    { name: '23:00 - 0:00', value: 23 },
  ]



  constructor(
    private userService:UserService,
    private messageService: MessageService,
    private router: Router,
    private blockService: AdmBlockService,
    private route: ActivatedRoute,
    private location: Location
  ) {}

  ngOnInit() : void {
    this.isLoggedIn=this.userService.isUserLoggedIn();
    this.accesstoken=this.userService.getAccessToken();
    this.checkUser();
    this.getBlock();
    this.getShows();
  }

  getBlock():void{
    const id = +this.route.snapshot.paramMap.get('id');
    this.blockService.getBlock(id)
      .subscribe(block => this.block = block);
  }
  getShows():void{
    this.blockService.getShowList().subscribe(shows => this.shows = shows);
  }

  save(): void {
    this.blockService.updateBlock(this.block)
      .subscribe(() => this.goBack());
  }
  delete(): void {
    this.blockService.deleteBlock(this.block)
      .subscribe(() => this.goBack());
  }
  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }
  getUserName(){
    return this.userService.getUserName();
  }

  private log(message: string) {
    this.messageService.add(`BeheerBlocksComponent: ${message}`);
  }
  goBack(): void {
    this.location.back();
  }
}
