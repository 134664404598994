import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';

import { AdmUser } from '../adm-user';
import { Password } from '../password';
import { AdmUserService }  from '../adm-user.service';
import {CurrentUser} from '../currentUser';


@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  user: AdmUser;
  isLoggedIn:boolean;
  newPassword: Password;
  currentUser: CurrentUser;

  constructor(
    private route: ActivatedRoute,
    private admUserService: AdmUserService,
    private location: Location,
    private messageService: MessageService,
    private router: Router,
    private userService:UserService,
  ) { }

  ngOnInit() : void {
    this.currentUser=new CurrentUser;
    this.newPassword=new Password;
    this.userService.getCurrentUserData().subscribe(user =>{
      this.currentUser=user;
      this.newPassword.userId=this.currentUser.id;
      this.newPassword.username=this.currentUser.userName;
    });


  }

  savepass(): void {
    this.userService.newPassword(this.newPassword)
      .subscribe(() => this.goTo('/beheer/users'));
  }
  goTo(url){
    this.router.navigateByUrl(url);
  }

}
