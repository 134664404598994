<div class='slideshowcontainer'>
  <div class='slideshow'>
    <slideshow [height]="'250px'"
              [minHeight]="'250px'"
              [autoPlay]="true"
              [showArrows]="false"
              [imageUrls]="imageUrls"
              [lazyLoad]="imageUrls?.length > 1"
              [autoPlayWaitForLazyLoad]="true"
              [showDots]="true"
              [autoPlayInterval]=5000
              >
    </slideshow>
  </div>
  <div class='slideshowOverlay'>
    <div class='zerocontainer logocontainer'>
      <div class='lfmlogo2' *ngIf="date>switchTime">
        <a routerLink="/home"><img src='/images/logos/logogroen.png'/></a><br>
        <span class='gezelligste'>De gezelligste zender van Brabant!</span>
<!--        <span class='login'><a routerLink="/login"><i class="fal fa-cog"></i> </a> </span>-->
      </div>
      <div class='lfmlogo' *ngIf="date<switchTime">
        <a routerLink="/home"><img src='/images/logos/logogroen.png'/></a><br>
        <span class='gezelligste'>De gezelligste zender van Brabant!</span>
        <span class='login'><a routerLink="/login"><i class="fal fa-cog"></i> </a> </span>
      </div>
    </div>
    <div class='zerocontainer'>
      <div class='nowplaying'>
        <div class='audioButtonContainer'><span id='audioButton' (click)="toggleAudio('LFMStream','audioButton')"><i class="fas fa-play"></i></span></div>
        <div class='luisterlive'><a routerLink="/live">Livestream</a></div>
        <div class='nowplayingtext' *ngIf="nowplaying">
          <div class='nowOn'>Now playing</div>
          <div class='programma'>{{nowplaying.showName}}
            <span class=herhaling > <!--Puur Non-Stop Radio-->  (Non-Stop)</span>
          </div>
<!--          <div class='presenters'>
            <div *ngFor="let presenter of nowplaying.presenters" class='dj'><a routerLink="/staff/{{presenter.id}}"><span [innerHTML]="presenter.name"></span></a></div>
            <div class='dj'><a routerLink="/staff/{{nowplaying.djId}}"><span [innerHTML]="nowplaying.dj"></span></a></div>
          </div>-->
        </div>
        <audio id='LFMStream' src="{{streamUrl}};"></audio>
      </div>
    </div>
  </div>
</div>



