
<div class='radioimage'><img src="/images/radio.png"></div>
<h1>Welkom bij L-FM.nl</h1>
<p>L-FM.nl is de online radiozender voor hits, oldies en evergreens voor de voormalige gemeente Landerd en ver daar buiten. Wij blijven u voorzien van lekker frisse en nostalgische radiouitzendingen om uw dag lekker op te frissen.<br>
  Met liefde blijven wij onze hits onderhouden, zodat u altijd lekker de radio op ons af kunt stemmen.<br>
<br>
  24 uur per dag, 7 dagen in de week. </p>




