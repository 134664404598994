import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { NowPlaying } from './data/nowplaying';
import { MessageService } from './message.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { catchError, tap } from 'rxjs/operators';

@Injectable({  providedIn: 'root'})

export class NowPlayingService {
  private nowPlayingUrl = environment.backendUrl+'scheduleEntries/nowPlaying';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) {   }

  /** GET NowPlaying from the server */
  getNowPlaying(): Observable<NowPlaying[]> {
    return this.http.get<NowPlaying[]>(this.nowPlayingUrl).pipe(
      tap(() => this.log('fetched nowPlaying ')),
      catchError(this.handleError('getNowPlaying', []))
    );
  }

  private log(message: string) {
    this.messageService.add(`NowPlayingService: ${message}`);
  }

  private handleError<T> (operation = 'operation', result?: T) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
