export class AdmBlock {
    id: number;
    showId: number;
    airweekday: number;
    airhour: number;
    description: string;
  }

  export class AdmShowList {
    id: number;
    name: string;
  }
