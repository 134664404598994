<div class='calendar'>
  <h1>Agenda</h1>
  <div *ngFor="let agendaitem of agendaItems">
    <div class='calendarItem'>
      <h2><a routerLink="/artikel/{{agendaitem.id}}"> {{agendaitem.title}} </a><label>{{agendaitem.expires|date:'longDate':'GMT':'nl-NL'}}</label></h2>
      <p><span [innerHTML]="agendaitem.content|slice:0:300|sanitizeHtml"></span><span class='readmore'>... <a routerLink="/artikel/{{agendaitem.id}}">[Lees Meer]</a></span></p>
    </div>
  </div>
  <div *ngIf='isLoggedIn' style='border:1px dashed crimson;padding:15px;'>
    <h3 style='margin:0px 0px 10px 0px;'>Agenda item toevoegen</h3>
    <input [(ngModel)]="agendaItem.title" style='width: 300px;' placeholder="Titel"/>
    <input type="date" [ngModel] ="agendaItem.expires | date:'yyyy-MM-dd':'Europe/Amsterdam'" (ngModelChange)="agendaItem.expires = $event">
    <button (click)="newCalendarItem()">AgendaItem toevoegen</button>
    <p style='font-size:12px; color:#777'>De beschrijving van dit agenda item kun je in het volgende scherm invullen</p>
  </div>
</div>
