// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envName: 'dev',
  backendUrl: 'https://l-fm.nl/api/',
  shoutCastServerUrl: 'https://stream.l-fm.nl:8443/',
  whitelistedDomains: ['stream.l-fm.nl', 'l-fm.nl'],
  blacklistedRoutes: [
'stream.l-fm.nl/api/oauth',
'/stream.l-fm.nl/api/oauth/.*/',
'stream.l-fm.nl/api/oauth/token',
'l-fm.nl/api/oauth',
    '/l-fm.nl/api/oauth/.*/',
'l-fm.nl/api/oauth/token'
]
  // backendUrl: 'http://192.168.0.20/api/'
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
