import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AgendaItem } from './data/agendaitem';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({ providedIn: 'root' })

export class AgendaItemService {
  private agendaUrlFull = environment.backendUrl + 'articles/calendar';  // URL to web api
  private agendaSingleUrlFull = environment.backendUrl + 'articles';  // URL to web api
  private newsUrlFull = environment.backendUrl + 'articles/news';  // URL to web api
  private agendaUrl = environment.backendUrl + 'articles/calendar/upcoming';  // URL to web api
  private lfmNieuwsUrl = environment.backendUrl + 'articles/news/lfm/upcoming';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }


  /** GET LFMnieuws from the server */
  getLFMnieuws(): Observable<AgendaItem[]> {
    return this.http.get<AgendaItem[]>(this.lfmNieuwsUrl).pipe(
      tap(agendaitems => this.log('fetched LFM Nieuws')),
      catchError(this.handleError('getLFMnieuws', []))
    );
  }

  /** GET NEWS from the server */
  getNews(): Observable<AgendaItem[]> {
    return this.http.get<AgendaItem[]>(this.newsUrlFull).pipe(
      tap(agendaitems => this.log('fetched Regional News')),
      catchError(this.handleError('getNieuws' + this.newsUrlFull, []))
    );
  }

  /** GET Upcoming Agenda from the server */
  getUpcomingAgendaItems(): Observable<AgendaItem[]> {
    return this.http.get<AgendaItem[]>(this.agendaUrl).pipe(
      tap(agendaitems => this.log('fetched UpcomingCalendar')),
      catchError(this.handleError('getUpcomingAgendaItems', []))
    );
  }

  /** GET Agenda from the server */
  getAgendaItems(): Observable<AgendaItem[]> {
    return this.http.get<AgendaItem[]>(this.agendaUrlFull).pipe(
      tap(agendaitems => this.log('fetched agenda from ' + this.agendaUrlFull)),
      catchError(this.handleError('getAgendaItems', []))
    );
  }

  /** GET Agenda Item by id. Will 404 if id not found */
  getAgendaItem(id: number): Observable<AgendaItem> {
    const url = `${this.agendaSingleUrlFull}/${id}`;
    return this.http.get<AgendaItem>(url).pipe(
      tap(_ => this.log(`fetched agenda id=${id} from ` + this.agendaUrlFull)),
      catchError(this.handleError<AgendaItem>(`getAgendaItems id=${id}`))
    );
  }


  updateArticle(agendaitem: AgendaItem): Observable<any> {
    return this.http.put(this.agendaSingleUrlFull + '/' + agendaitem.id, agendaitem, httpOptions).pipe(
      tap(_ => this.log(`updated agendaitem id=${agendaitem.id}`)),
      catchError(this.handleError<any>('updateArticle'))
    );
  }
  deleteArticle(agendaitem: AgendaItem): Observable<any> {
    return this.http.delete(this.agendaSingleUrlFull + '/' + agendaitem.id, httpOptions).pipe(
      tap(_ => this.log(`deleted agendaitem id=${agendaitem.id}`)),
      catchError(this.handleError<any>('deleteArticle'))
    );
  }
  newArticle(agendaitem: AgendaItem): Observable<any> {
    return this.http.post(this.agendaSingleUrlFull, agendaitem, httpOptions).pipe(
      tap(_ => this.log(`added agendaitem id=${agendaitem.id}`)),
      catchError(this.handleError<any>('newArticle'))
    );
  }

  private log(message: string) {
    this.messageService.add(`AgendaService: ${message}`);
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
