import { Component, OnInit } from '@angular/core';
import { StaffMember } from '../data/staffmember';
import { StaffmemberService} from '../staffmember.service';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.css']
})
export class StaffComponent implements OnInit {
  staffmembers:StaffMember[];

  constructor(private staffService: StaffmemberService) { }

  ngOnInit() : void {
    this.getStaffMembers();
  }
  getStaffMembers():void{
    this.staffService.getStaff().subscribe(staffmembers => this.staffmembers = staffmembers);
  }


}
