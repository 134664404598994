import { Component, OnInit } from '@angular/core';
import {AgendaItemService} from "../agenda-item.service";
import {Router} from "@angular/router";
import {UserService} from "../user.service";
import {AgendaItem} from "../data/agendaitem";
import {ArticleType} from "../admin/enums/articleType";

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  isLoggedIn: boolean;
  isAdmin: boolean;
  agendaItems: AgendaItem[] = [];
  agendaItem: AgendaItem;
  date = new Date();

  constructor(
    private agendaItemService: AgendaItemService,
    private router: Router,
    private userService: UserService,
  ) {
    this.agendaItem = new AgendaItem();
  }

  ngOnInit(): void {
    this.getLFMnieuws();
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.isAdmin = this.userService.isAdminUser();
  }

  getLFMnieuws(): void {
    this.agendaItemService.getLFMnieuws().subscribe(agendaitems => this.agendaItems = agendaitems);
  }
  newLFMnews(): void {
    this.agendaItem.type = ArticleType.LFM_NEWS;
    this.agendaItemService.newArticle(this.agendaItem).subscribe(agendaitem => this.goTo('/beheer/article/' + agendaitem.id));
  }
  goTo(url) : void {
    this.router.navigateByUrl(url);
  }

}
