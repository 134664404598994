
<div class='calendar'>




  <div *ngFor="let agendaItem of agendaItems">
    <h1 class='headline'>{{agendaItem.title}}</h1>
    <p><span class="clickable" [innerHTML]="agendaItem.content|slice:0:400|readmore:400|nl2pbr|sanitizeHtml" (click)="goTo('/artikel/'+agendaItem.id)"></span></p>
  </div>
  <h2><a routerLink="/nieuws">Bekijk het regionieuws</a></h2>
  <div *ngIf='isAdmin' style='border:1px dashed crimson;padding:15px;'>
    <h3 style='margin:0px 0px 10px 0px;'>Nieuw LFM nieuws toevoegen</h3>
      <input [(ngModel)]="agendaItem.title" style='width: 300px;' placeholder="Titel"/>
      <button (click)="newLFMnews()">LFM Nieuws item toevoegen</button>
      <p style='font-size:12px; color:#777'>De beschrijving van dit nieuws item kun je in het volgende scherm invullen</p>
  </div>

</div>
