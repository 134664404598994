<div class='staff'>
  <div *ngFor="let staffmember of staffmembers">
    <div class='staffmember'>
      <div class='imagecontainer'><img src='/images/photos/{{staffmember.id}}.dj.jpg'> </div>
      <h2><a routerLink="/staff/{{staffmember.id}}"> {{staffmember.name}} </a></h2>           
      <div>
        <span [innerHTML]="staffmember.description|slice:0:350|sanitizeHtml"></span>
        <span>... <a routerLink="/staff/{{staffmember.id}}">[Lees Meer]</a></span>
      </div>
      <div class='clearer'></div>
    </div>
  </div>
</div>