import {Component, OnInit} from '@angular/core';
import {Day} from '../enums/day';
import {UserService} from '../../user.service';
import {MessageService} from '../../message.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AdmBlock} from '../adm-block';
import {AdmBlockService} from '../adm-block.service';
import {Location} from '@angular/common';
import {ScheduleService} from '../../schedule.service';
import {Hour} from '../../data/hour';
import {ScheduleEntry} from '../scheduleEntry';

@Component({
  selector: 'app-programminghour',
  templateUrl: './programminghour.component.html',
  styleUrls: ['./programminghour.component.css']
})
export class ProgramminghourComponent implements OnInit {
  blocks: AdmBlock[];
  block: AdmBlock;
  hour: number;
  day: number;
  dayString: string;
  scheduleHours: Hour[];
  scheduleEntryId: number;
  scheduleEntry: ScheduleEntry;

  days = [
    {name: Day[Day.Maandag], value: 1},
    {name: Day[Day.Dinsdag], value: 2},
    {name: Day[Day.Woensdag], value: 3},
    {name: Day[Day.Donderdag], value: 4},
    {name: Day[Day.Vrijdag], value: 5},
    {name: Day[Day.Zaterdag], value: 6},
    {name: Day[Day.Zondag], value: 7},
  ];
  hours = [
    {name: '8:00 - 9:00', value: 8},
    {name: '9:00 - 10:00', value: 9},
    {name: '10:00 - 11:00', value: 10},
    {name: '11:00 - 12:00', value: 11},
    {name: '12:00 - 13:00', value: 12},
    {name: '13:00 - 14:00', value: 13},
    {name: '14:00 - 15:00', value: 14},
    {name: '15:00 - 16:00', value: 15},
    {name: '16:00 - 17:00', value: 16},
    {name: '17:00 - 18:00', value: 17},
    {name: '18:00 - 19:00', value: 18},
    {name: '19:00 - 20:00', value: 19},
    {name: '20:00 - 21:00', value: 20},
    {name: '21:00 - 22:00', value: 21},
    {name: '22:00 - 23:00', value: 22},
    {name: '23:00 - 0:00', value: 23},
  ];

  constructor(
    private userService: UserService,
    private messageService: MessageService,
    private router: Router,
    private blockService: AdmBlockService,
    private route: ActivatedRoute,
    private location: Location,
    private scheduleService: ScheduleService
  ) {
    this.hour = parseInt(this.route.snapshot.paramMap.get('hour'));
    this.day = parseInt(this.route.snapshot.paramMap.get('day'));
    switch (this.day) {
      case 1:
        this.dayString = 'Maandag';
        break;
      case 2:
        this.dayString = 'Dinsdag';
        break;
      case 3:
        this.dayString = 'Woensdag';
        break;
      case 4:
        this.dayString = 'Donderdag';
        break;
      case 5:
        this.dayString = 'Vrijdag';
        break;
      case 6:
        this.dayString = 'Zaterdag';
        break;
      case 7:
        this.dayString = 'Zondag';
        break;
    }
    this.block = new AdmBlock;
  }

  ngOnInit(): void {
    this.getBlocks();
    this.getHours();
  }

  getBlocks(): void {
    this.blockService.getBlocks().subscribe(blocks => {
      this.blocks = blocks;
    });
  }

  getHours(): void {
    this.scheduleService.getHours().subscribe(hours => {
      this.scheduleHours = hours;
      this.block.id = (this.scheduleHours[this.hour].weekdays[this.day - 1].showBlockId);
      this.scheduleEntryId = (this.scheduleHours[this.hour].weekdays[this.day - 1].scheduleEntryId);
    });
  }

  updateSchedule() {
    this.scheduleEntry = new ScheduleEntry;
    this.scheduleEntry.showBlockId = this.block.id;
    this.scheduleEntry.hour = this.hour;
    this.scheduleEntry.weekday = this.day;
    this.scheduleEntry.id = this.scheduleEntryId;

    this.scheduleService.updateSchedule(this.scheduleEntry)
      .subscribe(() => this.goTo('/beheer/programming'));

  }

  goBack() {
    this.router.navigateByUrl('/beheer/users');
  }

  goTo(url) {
    this.router.navigateByUrl(url);
  }

}
