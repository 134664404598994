import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Show } from './data/show';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({  providedIn: 'root'})

export class ShowService {
  private showsUrl = environment.backendUrl+'shows';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) {   }

      /** GET Shows from the server */
  getShows(): Observable<Show[]> {
    return this.http.get<Show[]>(this.showsUrl).pipe(
      tap(shows => this.log(`fetched shows from `+this.showsUrl)),
      catchError(this.handleError('getShows', []))
    );
  }

  getShow(id: number): Observable<Show> {
    const url = `${this.showsUrl}/${id}`;
    return this.http.get<Show>(url).pipe(
      tap(_ => this.log(`fetched show id=${id}`)),
      catchError(this.handleError<Show>(`getShow id=${id}`))
    );
  }
  updateShow (show: Show): Observable<any> {    
    return this.http.put(this.showsUrl+'/'+show.id, show, httpOptions).pipe(
      tap(_ => this.log(`updated show id=${show.id}`)),
      catchError(this.handleError<any>('updateShow'))
    );
  }
  deleteShow (show: Show): Observable<any> {    
    return this.http.delete(this.showsUrl+'/'+show.id, httpOptions).pipe(
      tap(_ => this.log(`deleted show id=${show.id}`)),
      catchError(this.handleError<any>('deleteShow'))
    );
  }
  newShow (show: Show): Observable<any> {    
    return this.http.post(this.showsUrl, show, httpOptions).pipe(
      tap(_ => this.log(`added show`)),
      catchError(this.handleError<any>('newShow'))
    );
  }

  private log(message: string) {
    this.messageService.add(`NowplayingService: ${message}`);
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }  
}
