import { Component, OnInit } from '@angular/core';
import { AgendaItem } from '../data/agendaitem';
import { UserService } from '../user.service';
import { ArticleType } from '../admin/enums/articleType';
import { Router } from '@angular/router';
import * as xml2js from 'xml2js';
import { HttpClient } from '@angular/common/http';
import {AgendaItemService} from '../agenda-item.service';



@Component({
  selector: 'app-lfmnieuws',
  templateUrl: './lfmnieuws.component.html',
  styleUrls: ['./lfmnieuws.component.css']
})
export class LfmnieuwsComponent implements OnInit {

  isLoggedIn: boolean;
  isAdmin: boolean;
  agendaItems: AgendaItem[];
  agendaItem: AgendaItem;
  date = new Date();


  constructor(
    private agendaItemService: AgendaItemService,
    private router: Router,
    private userService: UserService,
    private http: HttpClient) {
    this.agendaItem = new AgendaItem;

  }

  ngOnInit() : void {
    console.log('Init');
    this.getLFMnieuws();
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.isAdmin = this.userService.isAdminUser();
  }

  getBackgroundImage(url): string {
    url = url.images[0].image[0]._;
    return 'url("' + url + '")';
  }
  getLFMnieuws(): void {
    this.agendaItemService.getLFMnieuws().subscribe(agendaitems => this.agendaItems = agendaitems);
  }

  newLFMnews(): void {
    this.agendaItem.type = ArticleType.LFM_NEWS;
    this.agendaItemService.newArticle(this.agendaItem).subscribe(agendaitem => this.goTo('/beheer/article/' + agendaitem.id));
  }
  goTo(url) : void {
    this.router.navigateByUrl(url);
  }

}
