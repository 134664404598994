<div class='artikel'>
    <h2>L-FM Live Stream: Kies uw favoriete speler</h2>
     


    <table width="100%">
  <tr>
      <td valign="top" width="33%">
        <h3><i class="far fa-desktop"></i> Vanaf uw computer </h3>
        <a href="{{shoutCastServerUrl}}listen.pls"><img src="/images/icons/shoutcast.png" /></a><br>
    <p>Klik op de link, en sla het bestand op dat naar u wordt verzonden. U kunt dit bestand dan openen met uw favoriete media speler die shoutcast ondersteunt.</p>
      </td>
      <td valign="top" width="33%">
        <h3><i class="fab fa-html5"></i> Vanaf uw Browser</h3><br>
        <p style="text-align:center; margin:0"><audio src="{{shoutCastServerUrl}};" controls=""></audio></p>
        <p>Werkt op iedere moderne browser</p>
        <p><strong>U kunt beter op de afspeelknop bovenin drukken zodat de muziek door gaat op iedere pagina van deze site.</strong></p></td>
                 
  </tr>
    </table>
    
    <table width="100%">
  <tr>
      <td valign="top" width="33%">
        <h3><i class="fab fa-android"></i> Android en AndroidTV</h3>
        <p class='playstore'><a href="https://play.google.com/store/apps/details?id=com.nullsoft.winamp"><i class="fab fa-google-play"></i><span>Google Play</span></a></p>
    <p>Installeer TuneIn via de Google Playstore. Start TuneIn en zoek daar naar '<b>Landerd</b>' en dan vind je meteen onze radio zender.</p>
    <p>Als u een Android TV heeft kunt u op deze manier ook naar L-FM luisteren.<br>
      Bekende merken die AndroidTV gebruiken zijn: <b>Philips, Sony</b> en <b>Sharp</b></p>
      </td>
      <td valign="top" width="33%">
        <h3><i class="fab fa-apple"></i> iPhone / iPad</h3>
        <p>Gebruik uw browser Safari en gebruik de Browser speler hierboven</p></td>

  </tr>
    </table>
</div>
