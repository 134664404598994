export class AdmUser {
    id: number;
    username: string;
    password: string;
    email: string;
    firstname: string;
    lastname: string;
    enabled: boolean;
    roleIds: string[];
  }
