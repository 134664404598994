<div class='shows'>
  <ng-container>
    <h1>Contact</h1>
    <p>L-FM is gestopt als gemeentezender voor de gemeente Landerd. Online gaat de zender echter door als een non-stop zender.<br>
      Als u contact met ons op wilt nemen, kan dat via info@l-fm.nl
    </p>
    <h2>Frequenties</h2>
    <p>
      U kunt naar ons luisteren via onze website, via tuneIn en via uw internet radio.
    </p>
  </ng-container>
</div>
