import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { MessageService } from '../message.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-beheer',
  templateUrl: './beheer.component.html',
  styleUrls: ['./beheer.component.css']
})
export class BeheerComponent implements OnInit {

  isLoggedIn:boolean;

  constructor(
    private currentUser:UserService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() : void {
    this.isLoggedIn=this.currentUser.isUserLoggedIn();
    //this.checkUser();

  }
  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }
  getUsername(){
    return this.currentUser.getUserName();
  }
  private log(message: string) {
    this.messageService.add(`BeheerComponent: ${message}`);
  }

}
