<div class='admin'>
    <app-beheer-nav></app-beheer-nav>
  Article
    <div class='adminpanel' *ngIf='agendaItem'>
    <h2>Artikel: {{agendaItem.title}}</h2>
    <table cellpadding=5px>
      <tr>
        <td>Titel</td>
        <td><input [(ngModel)]="agendaItem.title" style='width: 300px;' placeholder="Titel"/></td>
      </tr>
      <tr>
          <td>Beschrijving</td>
          <td><textarea rows="20" cols="90"  required [(ngModel)]="agendaItem.content"></textarea></td>
        </tr>

        <tr>
            <td nowrap>Verloopt op / Datum:</td>
            <input type="date" [ngModel] ="agendaItem.expires | date:'yyyy-MM-dd':'Europe/Amsterdam'" (ngModelChange)="agendaItem.expires = $event">
          </tr>



    </table>
    <button (click)="save()">Opslaan</button>
    <button (click)="delete()">Verwijder</button>

  </div>

  </div>
