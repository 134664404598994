export class Show {
    id: number;
    name: string;
    description: string;
    hasIcon: boolean;
    hasPicture: boolean;
    hasBackgorund: boolean;
    showBlocks:ShowBlock[];
  }
  
export class ShowBlock {
  id: number;
  description: string;
  airweekday: number;
  airhour: number;
  scheduleEntries: ScheduleEntry[];
  show:Show;
}

export class ScheduleEntry {
  id: number;
  weekday: number;
  hour: number;
  showBlock: ShowBlock;
}