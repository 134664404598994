
export class Schedule{
  weekdays: weekday[];
}

export class weekday{
  hours: hour[];
}
export class hour{
  hourNumber: number;
  programId: number;

}
export class programEntry{
  programId: number;

}


export class Program{
  id: number;
  name: string;
  description: string;
}

export const SCHEDULE: Schedule = {
  weekdays: [
    /* Monday */
    {hours:[
      {hourNumber: 0, programId: 1},
      {hourNumber: 1, programId: 1},
      {hourNumber: 2, programId: 1},
      {hourNumber: 3, programId: 1},
      {hourNumber: 4, programId: 1},
      {hourNumber: 5, programId: 1},
      {hourNumber: 6, programId: 3},
      {hourNumber: 7, programId: 3},
      {hourNumber: 8, programId: 7},
      {hourNumber: 9, programId: 7},
      {hourNumber: 10, programId: 3},
      {hourNumber: 11, programId: 3},
      {hourNumber: 12, programId: 3},
      {hourNumber: 13, programId: 3},
      {hourNumber: 14, programId: 3},
      {hourNumber: 15, programId: 3},
      {hourNumber: 16, programId: 4},
      {hourNumber: 17, programId: 4},
      {hourNumber: 18, programId: 5},
      {hourNumber: 19, programId: 5},
      {hourNumber: 20, programId: 5},
      {hourNumber: 21, programId: 5},
      {hourNumber: 22, programId: 5},
      {hourNumber: 23, programId: 6}
    ]},
    /* Tuesday */
    {hours:[
        {hourNumber: 0, programId: 1},
        {hourNumber: 1, programId: 1},
        {hourNumber: 2, programId: 1},
        {hourNumber: 3, programId: 1},
        {hourNumber: 4, programId: 1},
        {hourNumber: 5, programId: 1},
        {hourNumber: 6, programId: 3},
        {hourNumber: 7, programId: 3},
        {hourNumber: 8, programId: 7},
        {hourNumber: 9, programId: 7},
        {hourNumber: 10, programId: 3},
        {hourNumber: 11, programId: 3},
        {hourNumber: 12, programId: 3},
        {hourNumber: 13, programId: 3},
        {hourNumber: 14, programId: 3},
        {hourNumber: 15, programId: 3},
        {hourNumber: 16, programId: 4},
        {hourNumber: 17, programId: 4},
        {hourNumber: 18, programId: 9},
        {hourNumber: 19, programId: 9},
        {hourNumber: 20, programId: 5},
        {hourNumber: 21, programId: 5},
        {hourNumber: 22, programId: 5},
        {hourNumber: 23, programId: 6}
      ]},
    /* Wednesday */
    {hours:[
        {hourNumber: 0, programId: 1},
        {hourNumber: 1, programId: 1},
        {hourNumber: 2, programId: 1},
        {hourNumber: 3, programId: 1},
        {hourNumber: 4, programId: 1},
        {hourNumber: 5, programId: 1},
        {hourNumber: 6, programId: 3},
        {hourNumber: 7, programId: 3},
        {hourNumber: 8, programId: 7},
        {hourNumber: 9, programId: 7},
        {hourNumber: 10, programId: 3},
        {hourNumber: 11, programId: 3},
        {hourNumber: 12, programId: 3},
        {hourNumber: 13, programId: 3},
        {hourNumber: 14, programId: 3},
        {hourNumber: 15, programId: 3},
        {hourNumber: 16, programId: 4},
        {hourNumber: 17, programId: 4},
        {hourNumber: 18, programId: 5},
        {hourNumber: 19, programId: 5},
        {hourNumber: 20, programId: 11},
        {hourNumber: 21, programId: 11},
        {hourNumber: 22, programId: 5},
        {hourNumber: 23, programId: 6}
      ]},
    /* Thursday */
    {hours:[
        {hourNumber: 0, programId: 1},
        {hourNumber: 1, programId: 1},
        {hourNumber: 2, programId: 1},
        {hourNumber: 3, programId: 1},
        {hourNumber: 4, programId: 1},
        {hourNumber: 5, programId: 1},
        {hourNumber: 6, programId: 3},
        {hourNumber: 7, programId: 3},
        {hourNumber: 8, programId: 7},
        {hourNumber: 9, programId: 7},
        {hourNumber: 10, programId: 3},
        {hourNumber: 11, programId: 3},
        {hourNumber: 12, programId: 3},
        {hourNumber: 13, programId: 3},
        {hourNumber: 14, programId: 3},
        {hourNumber: 15, programId: 3},
        {hourNumber: 16, programId: 4},
        {hourNumber: 17, programId: 4},
        {hourNumber: 18, programId: 5},
        {hourNumber: 19, programId: 5},
        {hourNumber: 20, programId: 5},
        {hourNumber: 21, programId: 9},
        {hourNumber: 22, programId: 9},
        {hourNumber: 23, programId: 6}
      ]},
    /* Friday */
    {hours:[
        {hourNumber: 0, programId: 1},
        {hourNumber: 1, programId: 1},
        {hourNumber: 2, programId: 1},
        {hourNumber: 3, programId: 1},
        {hourNumber: 4, programId: 1},
        {hourNumber: 5, programId: 1},
        {hourNumber: 6, programId: 3},
        {hourNumber: 7, programId: 3},
        {hourNumber: 8, programId: 7},
        {hourNumber: 9, programId: 7},
        {hourNumber: 10, programId: 3},
        {hourNumber: 11, programId: 3},
        {hourNumber: 12, programId: 3},
        {hourNumber: 13, programId: 3},
        {hourNumber: 14, programId: 3},
        {hourNumber: 15, programId: 3},
        {hourNumber: 16, programId: 4},
        {hourNumber: 17, programId: 4},
        {hourNumber: 18, programId: 9},
        {hourNumber: 19, programId: 5},
        {hourNumber: 20, programId: 5},
        {hourNumber: 21, programId: 5},
        {hourNumber: 22, programId: 5},
        {hourNumber: 23, programId: 10}
      ]},
    /* Saturday */
    {hours:[
        {hourNumber: 0, programId: 10},
        {hourNumber: 1, programId: 10},
        {hourNumber: 2, programId: 1},
        {hourNumber: 3, programId: 1},
        {hourNumber: 4, programId: 1},
        {hourNumber: 5, programId: 1},
        {hourNumber: 6, programId: 3},
        {hourNumber: 7, programId: 3},
        {hourNumber: 8, programId: 4},
        {hourNumber: 9, programId: 4},
        {hourNumber: 10, programId: 3},
        {hourNumber: 11, programId: 3},
        {hourNumber: 12, programId: 7},
        {hourNumber: 13, programId: 7},
        {hourNumber: 14, programId: 3},
        {hourNumber: 15, programId: 3},
        {hourNumber: 16, programId: 4},
        {hourNumber: 17, programId: 4},
        {hourNumber: 18, programId: 3},
        {hourNumber: 19, programId: 3},
        {hourNumber: 20, programId: 7},
        {hourNumber: 21, programId: 7},
        {hourNumber: 22, programId: 8},
        {hourNumber: 23, programId: 6}
      ]},
    /* Sunday */
    {hours:[
        {hourNumber: 0, programId: 1},
        {hourNumber: 1, programId: 1},
        {hourNumber: 2, programId: 1},
        {hourNumber: 3, programId: 1},
        {hourNumber: 4, programId: 1},
        {hourNumber: 5, programId: 1},
        {hourNumber: 6, programId: 2},
        {hourNumber: 7, programId: 2},
        {hourNumber: 8, programId: 2},
        {hourNumber: 9, programId: 2},
        {hourNumber: 10, programId: 2},
        {hourNumber: 11, programId: 8},
        {hourNumber: 12, programId: 11},
        {hourNumber: 13, programId: 11},
        {hourNumber: 14, programId: 3},
        {hourNumber: 15, programId: 3},
        {hourNumber: 16, programId: 4},
        {hourNumber: 17, programId: 4},
        {hourNumber: 18, programId: 5},
        {hourNumber: 19, programId: 5},
        {hourNumber: 20, programId: 5},
        {hourNumber: 21, programId: 5},
        {hourNumber: 22, programId: 5},
        {hourNumber: 23, programId: 6}
      ]},
  ]

}



export const PROGRAMS: Program[] = [
  {
    id: 1,
    name: 'Nachttrein',
    description: '',
  },
  {
    id: 2,
    name: 'Wakker worden!',
    description: '',
  },
  {
    id: 3,
    name: 'Vitamine L',
    description: '',
  },
  {
    id: 4,
    name: 'Onderweg',
    description: '',
  },
  {
    id: 5,
    name: 'Avondprogramma',
    description: '',
  },
  {
    id: 6,
    name: 'Romantisch',
    description: '',
  },
  {
    id: 7,
    name: 'Golden Oldies',
    description: '',
  },
  {
    id: 8,
    name: 'Duits',
    description: '',
  },
  {
    id: 9,
    name: 'Country',
    description: '',
  },
  {
    id: 10,
    name: 'Disco',
    description: '',
  },
  {
    id: 11,
    name: 'Mari\'s Feestje',
    description: '',
  }
]
