<div class='admin'>
  <app-beheer-nav></app-beheer-nav>

  <div class='adminShows adminpanel'>
    <h2>Programma blokken</h2>
    <p>Klik op een programma blok om deze aan te passen of te verwijderen.<br>
    Een programma blok is 1 uur van een bestaand radio programma. Dit is zo ingedeeld zodat we verschillende DJ's kunnen toewijzen aan verschillende uren.</p>
    <div *ngFor="let block of blocks">
        <a routerLink="/beheer/blocks/{{block.id}}" [innerHTML]="block.description|sanitizeHtml"></a>
    </div>
  </div>
  <h3>Nieuw programma blok</h3>
  <input [(ngModel)]="block.description" style='width: 300px;' placeholder="Naam blok"/>
  <select [(ngModel)]="block.showId">
      <option *ngFor="let s of shows" value='{{s.id}}' [innerHTML]="s.name|sanitizeHtml"></option>
    </select>
  <button (click)="newBlock()">Blok toevoegen</button>

</div>