<div class='admin'>
    <app-beheer-nav></app-beheer-nav>

  <br>
  <h2>{{dayString}} van {{hour}}:00 tot {{hour+1}}:00</h2>
  
  <select [(ngModel)]="block.id">
      <option *ngFor="let b of blocks" value='{{b.id}}' [innerHTML]="b.description|sanitizeHtml"></option>
    </select>
<button (click)='updateSchedule()'>Opslaan</button>

</div>