import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {Hour} from './data/hour';
import {MessageService} from './message.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {catchError, tap} from 'rxjs/operators';
import {ScheduleEntry} from './admin/scheduleEntry';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  private scheduleUrl = environment.backendUrl + 'scheduleEntries/hoursWithDays';  // URL to web api
  private scheduleUpdateUrl = environment.backendUrl + 'scheduleEntries';  // URL to web api


  constructor(
    private http: HttpClient,
    private messageService: MessageService) {
  }

  /** GET Shows from the server */
  getHours(): Observable<Hour[]> {
    return this.http.get<Hour[]>(this.scheduleUrl).pipe(
      tap(hours => this.log('fetched hours from ' + this.scheduleUrl)),
      catchError(this.handleError('getHours', []))
    );
  }

  getBlocks(): Observable<Hour[]> {
    return this.http.get<Hour[]>(this.scheduleUrl).pipe(
      tap(hours => this.log('fetched hours from ' + this.scheduleUrl)),
      catchError(this.handleError('getHours', []))
    );
  }

  updateSchedule(scheduleEntry: ScheduleEntry): Observable<ScheduleEntry> {
    console.log('Should be doing something');
    return this.http.put(this.scheduleUpdateUrl + '/' + scheduleEntry.id, scheduleEntry, httpOptions).pipe(
      tap(_ => this.log(`updated scheduleEntry id=${scheduleEntry.id}`)),
      catchError(this.handleError<any>('updateSchedule'))
    );
  }


  private log(message: string) {
    this.messageService.add(`NowplayingService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
