<div class='welcome'>
  <div class='shows'>

    <ng-container>

      <h1>
        Hoe kun je nu luisteren?
      </h1>
      <p>
        Ondanks dat we niet meer op FM of DAB+ te beluisteren zijn, betekent dit niet dat je ons niet meer kunt beluisteren, er zijn talloze manieren om te luisteren, sommigen zijn zelfs nog makkelijker dan voorheen!<br>
        Wij hebben natuurlijk niet íedere radio in huis om te testen of deze werkt, maar we hebben wel enkele voorbeelden, en wellicht werkt jouw radio wel hetzelfde.
      </p>
      <h2>Op een internet radio</h2>
      <img style="float:right" src="/images/renkforce.jpg">
      <p>
        Een internet radio is een radio die via WiFi of netwerkkabel aangesloten is op het internet. De meeste van deze radio's hebben een zenderlijst voorgeprogrammeerd waar LFM in opgenomen is.<br>
        Wat je dan moet doen is deze aansluiten met een netwerkkabel, of de Wifi instellen, en daarna ga je naar de optie Internet radio, kies je de regio uit: 'Nederland', en daar zoek je LFM op. Tip: Druk de 1 toets voor langere tijd in om deze voor te programmeren op knop 1. Dan hoef je daarna alleen nog maar op de 1 knop te drukken en hij begint te spelen.<br><br>
        Wat helaas weleens voor kan komen is dat de zenderlijst van het internet niet goed bijgewerkt wordt. Deze wordt door een internet dienst beheerd. Sommigen doen dit goed, sommigen minder. vTuner is hier bijvoorbeeld erg slecht in. Helaas kun je niet kiezen van welke internet dienst jouw radio gebruik maakt. Dit is per merk en model afhankelijk.<br><br>
        Maar toch kunnen we een aanbeveling maken: De Renkforce RF-IR-MONOV2. Deze is op bol.com en Conrad.nl te bestellen voor ongeveer €60. Waarom bevelen we deze aan? Om verschillende redenen:<br>
      </p>
      <ul>
        <li>De internet dienst houdt de zenderlijst netjes bij, en dus ook L-FM.nl</li>
        <li>Het apparaat staat het toe om 3 voorkeurzenders vast in te programmeren door het stream adres in te voeren. Dit werkt dus ook zonder de zenderlijst.</li>
        <li>Het heeft een aux uitgang waardoor je hem op een versterker aan kunt sluiten zodat het hele huis, en de buurt mee kan luisteren.</li>
        <li>Het heeft accu aan boord, dus je kunt hem ook van stroom loskoppelen en mee naar buiten nemen. Zorg wel dat je fatsoenlijke wifi dekking hebt in de tuin.</li>
        <li>Eenmaal ingesteld, is het zo simpel als aan/uit en klaar.</li>
      </ul>
      <h2>In de auto</h2>
      <img style="float:right" src="/images/columbus.jpg">
      <p>
        Ja, ook in de auto is het goed mogelijk om naar LFM te luisteren. Dit gaat eenvoudig via bluetooth. Nu kun je overal ter wereld in de auto luisteren.<br>
        Binnen Europa kan dit gewoon van je eigen databundel. Met 1GB in je bundel kun je ongeveer 1 dag luisteren<br>
        En als je het goed instelt, begint L-FM automatisch te spelen zodra je de auto start!<br>
        <br>
        Wat moet je doen?
      </p>
      <ol>
        <li>Koppel je telefoon met de auto via bluetooth (zet bluetooth aan op je telefoon, en laat de auto je telefoon zoeken)</li>
        <li>Wanneer nodig, schakel het invoer kanaal van de auto naar bluetooth. Op het systeem afgebeeld op de foto (Volkswagen/Audi/Skoda), druk je op Media en dan op bluetooth.</li>
        <li>Installeer de app JUKE.nl op je telefoon.</li>
        <li>In de juke App, zoek naar L-FM (met het streepje er tussen)</li>
        <li>Druk op afspelen. Als de telefoon en de auto gekoppeld zijn, zal Juke automatisch naar de auto afspelen. Moderne autos kunnen nu zelfs de juke app bedienen (afspelen/pauze).</li>
      </ol>
      <p>Als je de auto uitschakelt, zal je juke app automatisch stoppen met streamen. Als je de app laat draaien, en je start de auto later, dan zal deze automatisch weer afspelen.</p>

      <h2>Op je smartspeaker(s)</h2>
      <img style="float:right" src="/images/smartspeakers.webp">
      <p>
        Op je smartspeakers afspelen is erg eenvoudig en werkt ongeveer zoals in de auto.
      </p>
      <ol>
        <li>Installeer de JUKE.nl app op je telefoon, en zorg dat je telefoon is verbonden met het wifi netwerk van je smart speakers</li>
        <li>In de juke App, zoek naar L-FM (met het streepje er tussen)</li>
        <li>Druk op afspelen. Het geluid wordt nu via je telefoon afgespeeld. Nu moeten we het nog naar de smartspeaker sturen. Druk op de knop met het scherm en speaker, rechts onderin naast de afspeelknop in de juke app (zoals afgebeeld). <br>
          Hier zou je de smartspeakers in je huis moeten zien. Kies de speaker of speakergroep uit die je wil gebruiken, en voila!</li>
      </ol>
    </ng-container>
  </div>
</div>

