import { Component, OnInit } from '@angular/core';
import {PROGRAMS, SCHEDULE} from './programschedule';

@Component({
  selector: 'app-programmas',
  templateUrl: './programmas.component.html',
  styleUrls: ['./programmas.component.css']
})
export class ProgrammasComponent implements OnInit {
  schedule=SCHEDULE;
  programs=PROGRAMS;

  programName(programId: number):string{
    let returnValue = 'unknown';
    this.programs.forEach(program => {
      if(program.id === programId){
        returnValue=program.name;
      }
    });
    return returnValue;
  }

  constructor() {
    console.log('constructor');
  }

  ngOnInit(): void {
    console.log('programma component gestart');
  }

}
