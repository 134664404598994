import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.css']
})
export class LiveComponent implements OnInit {

  shoutCastServerUrl = environment.shoutCastServerUrl;

  constructor() { }

  ngOnInit() : void {
  }

}
