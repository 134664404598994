import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  date = new Date();

  switchTime = new Date('01/08/2022 5:00:00 PM');
  switchTime2 = new Date('01/11/2022 5:00:00 PM');

  constructor() { }

  ngOnInit(): void {
  }

}
