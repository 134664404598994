<div class='show'>
<div *ngIf="show">
    <div class='imagecontainer'>
      <picture *ngIf="show.hasPicture">
        <source srcset="/images/photos/{{show.id}}.show.webp" type="image/webp">
        <source srcset="/images/photos/{{show.id}}.show.png" type="image/png">
        <img src='/images/photos/{{show.id}}.show.jpg' alt="{{show.name}}">
      </picture>
    </div>
    <h2 [innerHTML]="show.name"></h2>
    <p [innerHTML]="show.description|nl2pbr|sanitizeHtml"></p>
    <div *ngIf='isAdmin'> <a routerLink="/beheer/shows/{{show.id}}">Aanpassen</a></div>
  </div>
</div>
