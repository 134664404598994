<div class='shows'>
  <ng-container>
    <h1>Adverteren</h1>
    <p>L-FM is gestopt als gemeentezender voor de gemeente Landerd. Online gaat de zender echter door als een non-stop zender.<br>
      Momenteel draait L-FM.nl onder een non-commerciële licentie. Adverteren is daarom niet meer mogelijk.
    </p>
    <p>
      Voor vragen, neem contact met ons op door een mailtje te sturen naar info@l-fm.nl
    </p>
  </ng-container>

</div>
