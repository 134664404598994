<div class='admin'>
    <app-beheer-nav></app-beheer-nav>
    <div class='adminpanel' *ngIf='staffmembers'>
    <h2>Medewerkers</h2>
    <div *ngFor="let staffmember of staffmembers">
      <a routerLink="/beheer/staff/{{staffmember.id}}"> {{staffmember.name}} </a>
    </div>
  </div>
  <h3>Nieuwe medewerker</h3>
  <input [(ngModel)]="newMember.name" style='width: 300px;' placeholder="Naam nieuwe medewerker"/><button (click)="newStaffMember()">Medewerker opslaan</button>
</div>