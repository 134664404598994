import { Injectable } from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import { Statistic } from './statistic';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { WeekdayStatisticPerHour } from '../data/weekdayStatisticPerHour';
import {Competition} from '../data/competition';
import {AzuraCastUpdate} from '../data/azuraCast';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class StatisticService {
  private statisticUrl = environment.backendUrl + 'statistics';  // URL to web api
  private competitionUrl = 'https://teez-media.com/LFM_Music_Manager/getAll.php';
  private messageSubject: Subject<AzuraCastUpdate> = new Subject<AzuraCastUpdate>();

  constructor(
    private http: HttpClient,
  ) { }

  getStatistics(): Observable<Statistic[]> {
    return this.http.get<Statistic[]>(this.statisticUrl).pipe(
      tap(shows => console.log('fetched statistics from ' + this.statisticUrl)),
      catchError(this.handleError('getStatistics', []))
    );
  }

  getStatistic(id: number): Observable<Statistic> {
    const url = `${this.statisticUrl}/${id}`;
    return this.http.get<Statistic>(url).pipe(
      tap(_ => console.log(`fetched Adm-User id=${id}`)),
      catchError(this.handleError<Statistic>('getStatistic id=${id}'))
    );
  }

  getCompetition(): Observable<Competition> {
    const url = this.competitionUrl;
    return this.http.get<Competition>(url).pipe(
      tap(_ => console.log('fetched getCompetition')),
      catchError(this.handleError<Competition>('getCompetition'))
    );
  }

  getAverageListenersPerHourLastMonth(): Observable<Array<Array<any>>> {
    const url = `${this.statisticUrl}/listeners/lastMonth/perHour`;
    return this.http.get<Array<Array<any>>>(url).pipe(
      tap(_ => console.log(`fetched data for statistics/listeners/lastMonth/perHour`)),
      catchError(this.handleError<Array<Array<any>>>('getAverageListebersPerHourLastMonth caused an error'))
    );
  }

  getAverageListenersPerHourLastMonthAsTable(): Observable<Array<WeekdayStatisticPerHour>> {
    const url = `${this.statisticUrl}/listeners/lastMonth/perHour/asTable`;
    return this.http.get<Array<WeekdayStatisticPerHour>>(url).pipe(
      tap(_ => console.log(`fetched data for statistics/listeners/lastMonth/perHour`)),
      map((result: WeekdayStatisticPerHour[]) => !result ? result : result.map(s => WeekdayStatisticPerHour.from(s))),
      catchError(this.handleError<Array<WeekdayStatisticPerHour>>('getAverageListebersPerHourLastMonth caused an error'))
    );
  }

  getAverageListenersPerHourLastMonthAsChartData(): Observable<any> {
    const url = `${this.statisticUrl}/listeners/lastMonth/perHour/chartData`;
    return this.http.get<any>(url).pipe(
      tap(_ => console.log(`fetched data for statistics/listeners/lastMonth/perHour`)),
      catchError(this.handleError<any>(`getAverageListebersPerHourLastMonth caused an error`))
    );
  }

  getListenersOverLastXDays(days: number): Observable<Array<Array<any>>> {
    const url = `${this.statisticUrl}/listeners/last` + days + `Days`;
    return this.http.get<Array<Array<any>>>(url).pipe(
      tap(_ => console.log(`fetched data for statistics/listeners/last` + days + `Days`)),
      catchError(this.handleError<Array<Array<any>>>(`getListenersOverLastXDays caused an error`))
    );
  }

  private handleError<T> (operation = 'operation', result?: T): any {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  getCurrentListeners(): Observable<string> {
    const url = `${this.statisticUrl}/listeners/current`;
    return this.http.get<string>(url).pipe(
      tap(_ => console.log(`fetched data for statistics/listeners/current`)),
      catchError(this.handleError<string>(`getCurrentListeners caused an error`))
    );
  }

  getListenersPerDayCalendar(): Observable<Array<Array<any>>> {
    const url = `${this.statisticUrl}/listeners/perDay`;
    return this.http.get<Array<Array<any>>>(url).pipe(
      tap(_ => console.log(`fetched data for statistics/listeners/perDay`)),
      catchError(this.handleError<Array<Array<any>>>(`getListenersPerDayCalendar caused an error`))
    );
  }

  getServerSentEvent(url: string): Observable<MessageEvent> {
    return new Observable((observer) => {
      const eventSource = new EventSource(url);


      eventSource.onmessage = (event) => {
        observer.next(event);
      };


      eventSource.onerror = (error) => {
        observer.error(error);
        eventSource.close();
      };


      return () => {
        eventSource.close();
      };
    });
  }

}
