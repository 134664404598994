import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'readmore'})

export class ReadmorePipe implements PipeTransform {

  transform(value: string, maxLength: number): string {
    console.log('in transform', value.length, maxLength);
    if(value.length>=maxLength){
      console.log('maxlength reached');
      value = value+'... [Lees Meer]'
    }

    return value;
  }
}
