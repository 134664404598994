import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-listeneverywhere',
  templateUrl: './listeneverywhere.component.html',
  styleUrls: ['./listeneverywhere.component.css']
})
export class ListeneverywhereComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
