import { Component, OnInit } from '@angular/core';
import { ScheduleService} from '../schedule.service';
import { Hour } from '../data/hour';
import { WeekdayStatisticPerHour } from '../data/weekdayStatisticPerHour';
import { UserService } from '../user.service';
import { StatisticService } from '../admin/statistic.service';
import { ColorUtils } from '../toolkit/ColorUtils';
import {weekday} from "../pages/programmas/programschedule";

@Component({
  selector: 'app-programming',
  templateUrl: './programming.component.html',
  styleUrls: ['./programming.component.css']
})
export class ProgrammingComponent implements OnInit {

  hours: Hour[];
  isAllowedToSeeHeatmap: boolean;
  showHeatmap: boolean;
  private weekdayStatisticsPerHour: Array<WeekdayStatisticPerHour>;
  maxValueInWeekdayStatisticsPerHour = 0;

  constructor(
    private scheduleService: ScheduleService,
    private userService: UserService,
    private statisticService: StatisticService
  ) {
    this.showHeatmap = false;
  }

  ngOnInit() : void {
    this.isAllowedToSeeHeatmap = this.userService.isUserLoggedIn() &&
      (this.userService.hasRole('ADMIN') || this.userService.hasRole('BOARD_MEMBER'));
      // && this.userService.hasPermission('VIEW_STATISTICS');
    this.getHours();
    this.getHeatmapData();
  }

  getHours(): void {
    this.scheduleService.getHours().subscribe(hours => {
      this.hours = hours;
      this.hours.forEach(hour => {
        hour.weekdays.forEach(weekday => {
          weekday.automation = true;
        });
      });
    });
  }

  getHeatmapData(): void {
    if (this.isAllowedToSeeHeatmap) {
      this.statisticService.getAverageListenersPerHourLastMonthAsTable().subscribe(s => {
        this.weekdayStatisticsPerHour = s;
        this.maxValueInWeekdayStatisticsPerHour = 0;
          this.weekdayStatisticsPerHour.forEach((h: WeekdayStatisticPerHour) => {
            const maxValueOfHour = (<WeekdayStatisticPerHour>h).getMaxValue();
            if (maxValueOfHour > this.maxValueInWeekdayStatisticsPerHour) {
              this.maxValueInWeekdayStatisticsPerHour = maxValueOfHour;
            }
        });
      });
    }
  }

  getFontWeightByHourAndDay(hour: number, weekday: number): string {
    const d = new Date();
    return d.getHours() === hour && d.getDay() === weekday ? 'bold' : 'normal';
  }

  isCurrentHourAndDay(hour: number, weekday: number): boolean {
    const d = new Date();
    return d.getHours() === hour && d.getDay() === weekday;
  }

  calculateBackgroundColorForHeatMap(hour: number, weekday: number, automation: boolean, live: boolean): string {
    if (this.showHeatmap && this.isAllowedToSeeHeatmap) {
      const currentHour: WeekdayStatisticPerHour = this.weekdayStatisticsPerHour.find((h: WeekdayStatisticPerHour) => h.hourOfDay === hour);
      if (currentHour instanceof WeekdayStatisticPerHour) {
        console.log('calculateBackgroundColorForHeatMap(hour: ' + hour + ', weekday: ' + weekday + ') => currentHour:');
        console.log(currentHour);
        const defaultBackground = automation ? 'rgba(66,133,244,0.3)' : (live ? 'rgba(219,68,55,0.3)' : 'rgba(244,180,0,0.3)');

        return this.calculateBackgroundColor(currentHour.getValueForDay(weekday), defaultBackground);
      }
    }

    return null;
  }

  private calculateBackgroundColor(valueForDay: number, defaultBackground: string) {
    const normalizedValue = valueForDay / this.maxValueInWeekdayStatisticsPerHour;
    // 0° is red, 120° green, 240° blue
    // const minHue = 240.0; // blue
    // const maxHue = 360.0; // red

    const minHue = 10.0; // red
    const maxHue = 120.0; // green

    // turn the gradient around (1 - normalizedValue) so red is better
    const hue = minHue + (maxHue - minHue) * (1 - normalizedValue);

    const backGround = 'hsl(' + hue + ', 80%, 75%)';
    // const backGround = 'linear-gradient(to bottom right, hsla(' + hue + ', 80%, 75%, 0.0) 0%, hsla(' + hue + ', 80%, 75%, 1.0) 100%), '
    //   + defaultBackground;

    console.log('backGround: ' + backGround);

    return backGround;
  }

  getToolTipForHeatMap(hour: number, weekday: number): string {
    if (this.showHeatmap && this.isAllowedToSeeHeatmap) {
      const currentHour: WeekdayStatisticPerHour = this.weekdayStatisticsPerHour.find((h: WeekdayStatisticPerHour) => h.hourOfDay === hour);
      if (currentHour instanceof WeekdayStatisticPerHour) {
        // console.log('getToolTipForHeatMap(hour: ' + hour + ', weekday: ' + weekday + ') => currentHour:');
        // console.log(currentHour);
        return currentHour.getValueForDay(weekday) + ' luisteraars';
      }
    }

    return null;
  }

}
