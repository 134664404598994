import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../user.service';

import { Show } from '../data/show';
import { ShowService }  from '../show.service';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {
  @Input() show: Show;
  isLoggedIn:boolean;
  isAdmin:boolean;

  constructor(
    private userService:UserService,
    private route: ActivatedRoute,
    private showService: ShowService,
    private location: Location
  ) { }

  ngOnInit() : void {
    this.getShow();
    this.isLoggedIn=this.userService.isUserLoggedIn();
    this.isAdmin=this.userService.isAdminUser();
  }
  getShow():void{
    const id = +this.route.snapshot.paramMap.get('id');
    this.showService.getShow(id)
      .subscribe(show => this.show = show);
  }
  goBack(): void {
    this.location.back();
  }

}
