<div class='admin'>
  <app-beheer-nav></app-beheer-nav>
  <div class='adminpanel' *ngIf='show'>
      <h2><span [innerHTML]="show.name|sanitizeHtml"></span></h2>
      <table>
        <tr>
          <td>Titel</td>
          <td><input [(ngModel)]="show.name" style='width: 300px;' placeholder="Titel"/></td>
        </tr>
        <tr>
            <td>Beschrijving</td>
            <td><textarea rows="20" cols="100" id="mailtext" required [(ngModel)]="show.description">Hi there</textarea></td>
          </tr>
      </table>
      
      <button (click)="save()">Opslaan</button>
      <button (click)="delete()">Verwijderen</button>
  

      <h3>Afbeelding uploaden</h3>
      <input type="file" (change)="onFileChanged($event)">
      <button type="Button" (click)="onUpload()">Uploadd</button>

  </div>

  
</div>