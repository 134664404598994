import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-medewerkers',
  templateUrl: './medewerkers.component.html',
  styleUrls: ['./medewerkers.component.css']
})
export class MedewerkersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
