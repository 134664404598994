import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { StaffMember } from './data/staffmember';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { catchError, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({  providedIn: 'root'})

export class StaffmemberService {
  private staffmembersUrl = environment.backendUrl+'crewmembers';  // URL to web api  

  constructor(
    private http: HttpClient,
    private messageService: MessageService) {   }

  /** GET NowPlaying from the server */
  getStaff(): Observable<StaffMember[]> {
    return this.http.get<StaffMember[]>(this.staffmembersUrl).pipe(
      tap(nowplaying => this.log('fetched staff ')),
      catchError(this.handleError('getStaff', []))
    );
  }

  getStaffmember(id: number): Observable<StaffMember> {
    const url = `${this.staffmembersUrl}/${id}`;
    return this.http.get<StaffMember>(url).pipe(
      tap(_ => this.log(`fetched staffmember id=${id}`)),
      catchError(this.handleError<StaffMember>(`getStaffmember id=${id}`))
    );
  }

  /** PUT: update the hero on the server */
  updateStaffmember(staffmember: StaffMember): Observable<any> {
    return this.http.put(this.staffmembersUrl+'/'+staffmember.id, staffmember, httpOptions).pipe(
      tap(_ => this.log(`updated staffmember id=${staffmember.id}`)),
      catchError(this.handleError<any>('updateStaffmember'))
    );
  }
  newStaffmember(staffmember: StaffMember): Observable<any> {
    return this.http.post(this.staffmembersUrl, staffmember, httpOptions).pipe(
      tap(_ => this.log('added staffmember')),
      catchError(this.handleError<any>('newStaffmember'))
    );
  }
  deleteStaffmember(staffmember: StaffMember): Observable<any> {
    return this.http.delete(this.staffmembersUrl+'/'+staffmember.id, httpOptions).pipe(
      tap(_ => this.log(`deleted staffmember id=${staffmember.id}`)),
      catchError(this.handleError<any>('deleteStaffmember'))
    );
  }

  private log(message: string) {
    this.messageService.add(`staffmemberService: ${message}`);
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
  
}



