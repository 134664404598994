import { Component, OnInit } from '@angular/core';
import { Show } from '../data/show';
import { ShowService} from '../show.service';

@Component({
  selector: 'app-shows',
  templateUrl: './shows.component.html',
  styleUrls: ['./shows.component.css']
})
export class ShowsComponent implements OnInit {
  shows:Show[];

  constructor(private showService: ShowService) { }

  ngOnInit() : void {
    this.getShows();
  }
  getShows():void{
    this.showService.getShows().subscribe(shows => this.shows = shows);
  }

}
