<div class='welcome'>
  <div class='shows'>
    <h1>
      Via Facebook
    </h1>
    <p>
      We hebben een nieuwe facebook pagina gemaakt. Hier kun je eenvoudig een leuk berichtje, en een verzoekje achterlaten.<br>
      Deel het met iedereen, laat weten wat jij wil horen, dan zorgen wij er voor dat jouw nummer gedraaid wordt!<br>
      <a href="https://www.facebook.com/lfm.nl/">Klik hier om naar facebook te gaan</a> en druk dan op bericht versturen.
    </p>

    <h1>
      Andere manieren?
    </h1>
    <p>
      We begrijpen dat niet iedereen Facebook heeft, er wordt gewerkt aan andere manieren om een verzoekje aan te vragen, maar dit kost nog wat meer tijd. To be continued.
    </p>



  </div>
</div>

