<div class='admin login'>
  
  <div *ngIf="!isLoggedIn" class=login>
    <h2>Aanmelden</h2>
  <input [(ngModel)]="user.userName" style='width: 300px;' placeholder="Gebruikersnaam"/><br>
  <input [(ngModel)]="user.password" style='width: 300px;' placeholder="wachtwoord" type="password"/><br>

  <button (click)="login()">Aanmelden</button>
  
  <p style='font-size:12px;'>Mocht de server herstart zijn, kan het de eerste keer een paar minuten duren voordat je aangemeld wordt.</p>
  <p *ngIf="error != null">{{error}}</p>
  </div>
  <div *ngIf="isLoggedIn" class=logout>
      <h2>Afmelden</h2>
  <button (click)="logOut()">Afmelden</button><br></div>

</div>
