<div class='admin'>
  <app-beheer-nav></app-beheer-nav>

  <div *ngIf="user">      
      <table>
          <tr>
            <td>Gebruikersnaam</td>
            <td><b>{{user.username}}</b></td>
          </tr>
          <tr>
              <td>Email</td>
              <td><input [(ngModel)]="user.email" style='width: 300px;' placeholder="email"/></td>
            </tr>
            <tr>
              <td>Voornaam</td>
              <td><input [(ngModel)]="user.firstname" style='width: 300px;' placeholder="Voornaam"/></td>
            </tr>
            <tr>
              <td>Achternaam</td>
              <td><input [(ngModel)]="user.lastname" style='width: 300px;' placeholder="Achternaam"/></td>
            </tr>

        </table>
        
    <button (click)="save()">Opslaan</button>
    <button (click)="delete()">Verwijderen</button>

        <h3>
            Username / Wachtwoord aanpassen
        </h3>
        <table>
          <tr>
            <td>Gebruikersnaam</td>
            <td>{{newPassword.username}}</td>
          </tr>
          <tr>
              <td>Nieuw Wachtwoord</td>
              <td><input [(ngModel)]="newPassword.newPassword" style='width: 300px;' placeholder="Nieuw wachtwoord" type='password'/></td>
            </tr>
          <tr>
              <td>Admin Wachtwoord</td>
              <td><input [(ngModel)]="newPassword.oldPassword" style='width: 300px;' placeholder="Admin wachtwoord" type='password'/></td>
            </tr>
      </table>
      <button (click)="savepass()">Update Password</button>

  </div>
  <div *ngIf="roles">  
    <div *ngIf="user">  
      <table>
        <tr>
          <td valign=top>
            <h3>Gebruikersrollen</h3>   
            <div *ngFor="let role of roles">
              <button (click)='addRole(role.id)'>Plus</button>
              {{role.id}} 
            </div>
          </td>
          <td valign=top>          
            <h3>Toegewezen rollen</h3>   
            <div *ngFor="let role of userRoles">
                <button (click)='removeRole(role)'>Min</button>
              {{role}}             
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>