import { ArticleType } from '../admin/enums/articleType';

export class AgendaItem {
  id: number;
  title: string;
  content: string;
  hasPicture: boolean;
  hasBackground: boolean;
  disabled: boolean;
  created: number;
  expires: Date;
  // expires: string;
  type: ArticleType;
}
