<div class='admin'>
  <app-beheer-nav></app-beheer-nav>


  <div class='shows'>
      <h2>Programmering</h2>
      <table cellspacing=1 cellpadding=3>
        <tbody>
          <tr>
            <th width=90>Tijd</th>
            <th>Maandag</th>
            <th>Dinsdag</th>
            <th>Woensdag</th>
            <th>Donderdag</th>
            <th>Vrijdag</th>
            <th>Zaterdag</th>          
            <th>Zondag</th>
          </tr>
            <tr *ngFor="let hour of hours|slice:8">
              <td class='hours'>{{hour.hour}}.00 - {{hour.hour+1}}.00</td>
              <td *ngFor="let weekday of hour.weekdays" [ngClass]="weekday.automation ? 'automation':(weekday.live ? 'live': 'normal')"><a routerLink="/beheer/programming/{{weekday.weekday}}/{{hour.hour}}" [innerHTML]="weekday.showBlockDescription|sanitizeHtml"></a></td>
            </tr>
        </tbody>
      </table>
      <div class='legenda'>
        <table>
          <tr><td><b>Legenda: </b></td><td class='live'>Live Show</td><td class='normal'>Herhaling</td><td class='automation'>Non-Stop</td></tr>
        </table>
      </div>
    </div>

</div>
