import { Component, OnInit } from '@angular/core';
import {AgendaItemService} from '../agenda-item.service';
import {Router} from '@angular/router';
import {UserService} from '../user.service';
import {AgendaItem} from '../data/agendaitem';
import {ArticleType} from '../admin/enums/articleType';

@Component({
  selector: 'app-agenda-items',
  templateUrl: './agenda-items.component.html',
  styleUrls: ['./agenda-items.component.css']
})
export class AgendaItemsComponent implements OnInit {
  isLoggedIn: boolean;
  isAdmin: boolean;
  agendaItem: AgendaItem;
  agendaItems: AgendaItem[];
  currentDate = new Date();
  oldDate: Date;
  rssData: null;

  constructor(
    private agendaItemService: AgendaItemService,
    private router: Router,

    private userService: UserService,
  ) {
    this.agendaItem = new AgendaItem;
  }

  ngOnInit() : void {
    this.agendaItem = new AgendaItem;
    this.agendaItem.expires = new Date();
    this.getAgendaItems();
    this.oldDate = new Date();
    this.oldDate.setMonth(this.oldDate.getMonth() - 3);
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.isAdmin = this.userService.isAdminUser();
  }



  getAgendaItems(): void {
    this.agendaItemService.getAgendaItems().subscribe(agendaitems => this.agendaItems = agendaitems);
  }
  getEpochDate(d) {
    return new Date(d * 1000);
  }
  newCalendarItem() {
    this.agendaItem.type = ArticleType.CALENDAR;
    this.agendaItem.expires = new Date(this.agendaItem.expires);
    this.agendaItem.expires.setHours(23, 59, 59, 0);
    this.agendaItemService.newArticle(this.agendaItem).subscribe(agendaitem => this.goTo('/beheer/article/' + agendaitem.id));
  }
  goTo(url) {
    this.router.navigateByUrl(url);
  }




}
