<h1>Overal te beluisteren</h1>
<table class="frequentielijst" width="100%">
  <tbody>
    <tr>
      <th width="33%" align="center">In de ether</th>
      <th width="33%" valign="top" align="center">Op de kabel</th>
      <th width="33%" align="left"><b>Glasvezel / ADSL</b></th>
    </tr>
    <tr>
      <td width="33%" align="center">FM 91.6 Mhz</td>
      <td width="33%" align="center">87.5 Mhz</td>
      <td width="33%" align="left">TriNed Kan. 872</td>
    </tr>
    <tr>
      <td width="33%" align="center">FM 105.0 Mhz</td>
      <td width="33%" align="center" class='internet'>Op internet</td>
      <td width="33%" align="left">KPN Kan. 1030 </td>
    </tr>
    <tr>
      <td width="33%" align="center">FM 106.6 Mhz</td>
      <td width="33%" align="center"><a routerLink="/live">Luister Live</a></td>
      <td width="33%" align="left">Telfort Kan. 1030</td>
    </tr>
    <tr>
      <td width="33%" align="center"><b>DAB+</b></td>
      <td width="33%" align="center"></td>
      <td width="33%" align="left">XS4All Kan. 1030</td>
    </tr>
    <tr>
      <td width="33%" align="center">6A 181.936Mhz</td>
      <td width="33%" align="center"></td>
      <td width="33%" align="center"></td>
    </tr>
  </tbody>
</table>
<p><b>Verzoekje?</b> Bel of mail ons op 0486-420577 of <a href="mailto://studio@l-fm.nl">studio@l-fm.nl</a></p>