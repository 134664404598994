<div class='admin'>
    <app-beheer-nav></app-beheer-nav>
    <div class='adminShows adminpanel' *ngIf='shows'>
      <h2>Programma's</h2>
      <p>Klik op een programma om deze aan te passen of te verwijderen</p>
        <div *ngFor="let show of shows">
            <a routerLink="/beheer/shows/{{show.id}}" [innerHTML]="show.name|sanitizeHtml"></a>
        </div>
  </div>
  <h3>Nieuw programma</h3>
  <input [(ngModel)]="show.name" style='width: 300px;' placeholder="Naam programma"/>
  <button (click)="newShow()">Programma toevoegen</button>
  
  </div>