import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../user.service';
import { StatisticService } from '../statistic.service';
import { Router } from '@angular/router';
import { Statistic } from '../statistic';

@Component({
  selector: 'app-statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css']
})
export class StatisticComponent implements OnInit {
  @Input() statistic: Statistic;
  isLoggedIn: boolean;
  isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private statisticService: StatisticService,
    private location: Location,
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() : void {
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.isAdmin = this.userService.isAdminUser();
    this.getStatistic();
  }

  getStatistic(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.statisticService.getStatistic(id).subscribe(statistic => this.statistic = statistic);
  }

  checkUser() {
    if (!this.isLoggedIn) {
      console.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }

}
