import { Component, OnInit } from '@angular/core';
import { User } from '../data/user';
import { Location } from '@angular/common';
import { UserService } from '../user.service';
import { MessageService } from '../message.service';
import { Router } from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import { of, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import {UserInfo} from '../admin/userInfo';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  user: User;
  isLoggedIn: boolean;

  model: any = {};
  loading = false;
  error = '';
  redirectUrl: string;
  isAdmin: string;

  // TODO: Make globally available
  userInfo: UserInfo;

  constructor(
    private location: Location,
    private messageService: MessageService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private userService: UserService

  ) {
    this.user = new User;
  }

  ngOnInit() : void {
    if (this.userService.getAccessToken() == null) {
      this.userService.logout();
    } else {
      this.router.navigate(['/beheer']);
    }
    this.isLoggedIn = this.userService.isUserLoggedIn();
  }

  login() {

    this.error = null;
    this.loading = true;

    this.authenticationService.login(this.user.userName, this.user.password)
      .subscribe(
        result => {
          this.loading = false;

          if (result) {
            this.userService.login(result);
            // console.log('Username:'+this.userService.getUserName());
            // console.log('Accesstoken:'+this.userService.getAccessToken());
            this.router.navigateByUrl('/beheer');
          } else {
            this.error = 'Username or password is incorrect';
          }
        },
        error => {
          this.handleError(error);
        }
      );

  }
  doSomething(): void {
    this.log('Is User?: ' + this.userService.isUser());
    this.log('Is Admin?: ' + this.userService.isAdminUser());
    this.log('Is Logged in? : ' + this.userService.isUserLoggedIn());
  }
  logOut(): void {
    this.userService.logout();
    console.log('Logged out (Hopefully)');
  }
  goBack(): void {
    this.location.back();
  }
  getAccessToken() : string {
    return this.userService.getAccessToken();
  }
  private log(message: string) {
    this.messageService.add(`LoginComponent: ${message}`);
  }

  private handleError(error: HttpErrorResponse) {
    console.log('error while trying to log in:');
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);

      this.error = 'Could not reach the server.';
      this.loading = false;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,

      if (error.status === 400 && (error.error.error === 'invalid_grant' || error.error.error_description === 'Bad credentials')) {
        console.log(
          `Backend returned code ${error.status}, ` +
          `body was: ${error.error}`);

        console.log('Username or password is incorrect');
        this.error = 'Username or password is incorrect';
        this.loading = false;
        return of(error);
      }
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }
}
