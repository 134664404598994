import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AdmBlock, AdmShowList } from './adm-block';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })

export class AdmBlockService {
  private blockUrl = environment.backendUrl + 'showblocks';  // URL to web api
  private showlistUrl = environment.backendUrl + 'shows/names';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) { }

  /** GET Blocks from the server */
  getBlocks(): Observable<AdmBlock[]> {
    return this.http.get<AdmBlock[]>(this.blockUrl).pipe(
      tap(blocks => this.log(`fetched Adm-block from ` + this.blockUrl)),
      catchError(this.handleError('getBlocks', []))
    );
  }
  getShowList(): Observable<AdmShowList[]> {
    return this.http.get<AdmShowList[]>(this.showlistUrl).pipe(
      tap(shows => this.log(`fetched Adm-showlist from ` + this.showlistUrl)),
      catchError(this.handleError('getShowList', []))
    );
  }

  getBlock(id: number): Observable<AdmBlock> {
    const url = `${this.blockUrl}/${id}`;
    return this.http.get<AdmBlock>(url).pipe(
      tap(_ => this.log(`fetched Adm-Block id=${id}`)),
      catchError(this.handleError<AdmBlock>(`getBlock id=${id}`))
    );
  }
  updateBlock(block: AdmBlock): Observable<any> {
    return this.http.put(this.blockUrl + '/' + block.id, block, httpOptions).pipe(
      tap(_ => this.log(`updated block id=${block.id}`)),
      catchError(this.handleError<any>('updateBlock'))
    );
  }
  deleteBlock(block: AdmBlock): Observable<any> {
    return this.http.delete(this.blockUrl + '/' + block.id, httpOptions).pipe(
      tap(_ => this.log(`deleted block id=${block.id}`)),
      catchError(this.handleError<any>('deleteBlock'))
    );
  }
  newBlock(block: AdmBlock): Observable<any> {
    return this.http.post(this.blockUrl, block, httpOptions).pipe(
      tap(_ => this.log(`added block`)),
      catchError(this.handleError<any>('newBlock'))
    );
  }

  private log(message: string) {
    this.messageService.add(`Admin-BlockService: ${message}`);
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }
}
