import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Show } from '../../data/show';
import { ShowService} from '../../show.service';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-adm-show',
  templateUrl: './adm-show.component.html',
  styleUrls: ['./adm-show.component.css']
})
export class AdmShowComponent implements OnInit {

  isLoggedIn:boolean;
  show:Show;
  selectedFile: File=null;

  constructor(
    private userService:UserService,
    private messageService: MessageService,
    private router: Router,
    private showService: ShowService,
    private route: ActivatedRoute,
    private location: Location,
    private http: HttpClient,

  ) { }

  ngOnInit() : void {
    this.isLoggedIn=this.userService.isUserLoggedIn();
    //this.checkUser();
    this.getShow();
  }
  getEnvironmentUrl(){
    return environment.backendUrl+'uploads/image';
  }

  getShow():void{
    const id = +this.route.snapshot.paramMap.get('id');
    this.showService.getShow(id)
      .subscribe(show => this.show = show);
  }

  onFileChanged(event) {
    this.selectedFile = event.target.files[0]
  }

  onFileSelected(event){
    this.selectedFile=<File>event.target.files[0];
  }

  onUpload(){
    const fd = new FormData();
    fd.append('file', this.selectedFile, 'photos/'+this.show.id+'show.jpg');
    //fd.append('filename', 'photos/'+this.show.id+'show.jpg');
    this.http.post(this.getEnvironmentUrl(),fd).subscribe(res => console.log(res));
  }

  save(): void {
    this.showService.updateShow(this.show)
      .subscribe(() => this.goBack());
  }
  delete(): void {
    this.showService.deleteShow(this.show)
      .subscribe(() => this.goTo('/beheer/shows'));
  }

  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }

  private log(message: string) {
    this.messageService.add(`BeheerBlocksComponent: ${message}`);
  }
  goBack(): void {
    this.location.back();
  }
  goTo(url){
    this.router.navigateByUrl(url);
  }


}
