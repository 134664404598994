import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AdmUser } from './adm-user';
import { MessageService } from '../message.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({  providedIn: 'root'})

export class AdmUserService {
  private userUrl = environment.backendUrl+'users';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) {   }

      /** GET Shows from the server */
  getUsers(): Observable<AdmUser[]> {
    return this.http.get<AdmUser[]>(this.userUrl).pipe(
      tap(shows => this.log(`fetched Adm-users from `+this.userUrl)),
      catchError(this.handleError('getUsers', []))
    );
  }

  getUser(id: number): Observable<AdmUser> {
    const url = `${this.userUrl}/${id}`;
    return this.http.get<AdmUser>(url).pipe(
      tap(_ => this.log(`fetched Adm-User id=${id}`)),
      catchError(this.handleError<AdmUser>(`getUser id=${id}`))
    );
  }

    /** PUT: update the hero on the server */
    save(user: AdmUser): Observable<any> {
      return this.http.put(this.userUrl+'/'+user.id, user, httpOptions).pipe(
        tap(_ => this.log(`updated staffmember id=${user.id}`)),
        catchError(this.handleError<any>('updateStaffmember'))
      );
    }
    savepass(user: AdmUser): Observable<any> {
      return this.http.put(this.userUrl+'/'+user.id+'/password', user, httpOptions).pipe(
        tap(_ => this.log(`updated staffmember id=${user.id}`)),
        catchError(this.handleError<any>('updateStaffmember'))
      );
    }
    create(user: AdmUser): Observable<any> {
      return this.http.post(this.userUrl, user, httpOptions).pipe(
        tap(_ => this.log(`added staffmember`)),
        catchError(this.handleError<any>('newStaffmember'))
      );
    }
    delete(user: AdmUser): Observable<any> {
      return this.http.delete(this.userUrl+'/'+user.id, httpOptions).pipe(
        tap(_ => this.log(`deleted staffmember id=${user.id}`)),
        catchError(this.handleError<any>('deleteStaffmember'))
      );
    }

  private log(message: string) {
    this.messageService.add(`Admin-UserService: ${message}`);
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      this.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }  
}