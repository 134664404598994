<div class='admin'>
  <app-beheer-nav></app-beheer-nav>

  <div *ngIf="staffmember">
    
      <table>
          <tr>
            <td>Titel</td>
            <td><input [(ngModel)]="staffmember.name" style='width: 300px;' placeholder="Titel"/></td>
          </tr>
          <tr>
              <td>Beschrijving</td>
              <td><textarea rows="20" cols="100" id="mailtext" required [(ngModel)]="staffmember.description"></textarea></td>
            </tr>
        </table>
  </div>
  <button (click)="save()">Opslaan</button>
  <button (click)="delete()">Verwijderen</button>
  
</div>