import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { MessageService } from './message.service';
import { environment } from '../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {JwtHelperService} from '@auth0/angular-jwt';
import {TOKEN_NAME} from './admin/auth';
import {AdmUser} from './admin/adm-user';
import {CurrentUser} from './admin/currentUser';
import {Password} from './admin/password';
import {Roles} from './admin/roles';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private admUserUrl = environment.backendUrl + 'users';  // URL to web api
  private currentUserUrl = environment.backendUrl + 'users/current/info';  // URL to web api
  private newPasswordUrl = environment.backendUrl + 'users';  // URL to web api
  private rolesUrl = environment.backendUrl + 'roles';  // URL to web api

  private grandedAuthorities: Array<string>;
  jwtHelper: JwtHelperService = new JwtHelperService();
  accessToken: string;
  isAdmin: boolean;
  user_name: string;
  userId: number;

  constructor(
    private messageService: MessageService,
    private http: HttpClient,
  ) { }

  isUserLoggedIn() {
    if (this.accessToken) {
      return true;
    } else {
      return false;
    }
  }
  getAccessToken() {
    return this.accessToken;
  }

  getUserName() {
    return this.user_name;
  }
  getCurrentUserData(): Observable<CurrentUser> {
    return this.http.get<CurrentUser>(this.currentUserUrl).pipe(
      tap(_ => this.log(`fetched CurrentUser from ` + this.currentUserUrl)),
      catchError(this.handleError<CurrentUser>(`getCurrentUserData`))
    );
  }


  newUser(user: AdmUser): Observable<any> {
    return this.http.post(this.admUserUrl, user, httpOptions).pipe(
      tap(_ => this.log(`added user id=${user.id}`)),
      catchError(this.handleError<any>('newUser'))
    );
  }

  newPassword (password: Password): Observable<any> {
    return this.http.put(this.newPasswordUrl + '/' + password.userId + '/password', password, httpOptions).pipe(
      tap(_ => this.log(`updated password id=${password.userId}`)),
      catchError(this.handleError<any>('newPassword'))
    );
  }

  getRoles(): Observable<Roles[]> {
    return this.http.get<Roles[]>(this.rolesUrl).pipe(
      tap(shows => this.log(`fetched Roles from ` + this.rolesUrl)),
      catchError(this.handleError('getRoles', []))
    );
  }

  login(accessToken: string) {
    const decodedToken = this.jwtHelper.decodeToken(accessToken);
    // console.log(decodedToken);
    this.grandedAuthorities = decodedToken.authorities;
    this.isAdmin = this.grandedAuthorities.some(el => el === 'ROLE_ADMIN');
    this.user_name = decodedToken.user_name;

    this.accessToken = accessToken;
    localStorage.setItem(TOKEN_NAME, accessToken);
  }

  logout() {
    this.accessToken = null;
    localStorage.removeItem(TOKEN_NAME);
    this.grandedAuthorities = null;
    this.isAdmin = false;
  }
  manualLogout() {
    this.logout();
  }

  isAdminUser(): boolean {
    return this.isAdmin;
  }

  isUser(): boolean {
    return this.accessToken && !this.isAdmin;
  }

  /**
   * Returns <c>true</c> if the currently logged in user has the requested role or permission.
   * @param authority the role (e.g. ROLE_XYZ) or permission (e.g. XYZ_PERMISSION) to look up
   */
  hasGrantedAuthority(authority: string) {
    if (this.grandedAuthorities == null || authority == null || authority.trim().length < 1) {
      return false;
    }
    return this.grandedAuthorities.some(el => el === authority);
  }

  /**
   * Returns <c>true</c> if the currently logged in user has the requested role.
   * @param role the role (e.g. ROLE_XYZ) to look up, 'ROLE_' can be omitted
   */
  hasRole(role: string) {
    if (this.grandedAuthorities == null || role == null || role.trim().length < 1) {
      return false;
    }
    role = role.startsWith('ROLE_') ? role : 'ROLE_' + role;
    return this.grandedAuthorities.some(el => el === role);
  }

  /**
   * Returns <c>true</c> if the currently logged in user has the requested role.
   * @param permission the permission (e.g. XYZ_PERMISSION) to look up, '_PERMISSION' can be omitted
   */
  hasPermission(permission: string) {
    if (this.grandedAuthorities == null || permission == null || permission.trim().length < 1) {
      return false;
    }
    permission = permission.endsWith('_PERMISSION') ? permission : permission + '_PERMISSION';
    return this.grandedAuthorities.some(el => el === permission);
  }

  private log(message: string) {
    this.messageService.add(`AgendaService: ${message}`);
  }
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
