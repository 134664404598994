import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { StaffComponent } from './staff/staff.component';
import { StaffMemberComponent } from './staffmember/staff-member.component';
import { ShowsComponent } from './shows/shows.component';
import { ShowComponent } from './show/show.component';
import { LiveComponent } from './live/live.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { BeheerComponent } from './beheer/beheer.component';
import { AdmBlocksComponent } from './admin/adm-blocks/adm-blocks.component';
import { AdmBlockComponent } from './admin/adm-block/adm-block.component';
import { AdmProgrammingComponent } from './admin/adm-programming/adm-programming.component';
import { AdmShowsComponent } from './admin/adm-shows/adm-shows.component';
import { AdmShowComponent } from './admin/adm-show/adm-show.component';
import { AdmStaffComponent } from './admin/adm-staff/adm-staff.component';
import { AdmUsersComponent } from './admin/adm-users/adm-users.component';
import { AdmUserComponent } from './admin/adm-user/adm-user.component';
import { ArticleComponent } from './admin/article/article.component';
import { AdmStaffmemberComponent } from './admin/adm-staffmember/adm-staffmember.component';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { ProgramminghourComponent } from './admin/programminghour/programminghour.component';
import { StatisticsComponent } from './admin/statistics/statistics.component';
import { StatisticComponent } from './admin/statistic/statistic.component';
import {AdverterenComponent} from './pages/adverteren/adverteren.component';
import {MedewerkersComponent} from './pages/medewerkers/medewerkers.component';
import {ProgrammasComponent} from './pages/programmas/programmas.component';
import {ContactComponent} from './pages/contact/contact.component';
import {HowToListenComponent} from './how-to-listen/how-to-listen.component';
import {NewsComponent} from './news/news.component';
import {VerzoekjeComponent} from './verzoekje/verzoekje.component';
import {AgendaItemDetailComponent} from './agenda-item-detail/agenda-item-detail.component';
import {AgendaItemsComponent} from './agenda-items/agenda-items.component';


const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', component: DashboardComponent },
  { path: 'agenda', component: AgendaItemsComponent },
  { path: 'hoe', component: HowToListenComponent },
  { path: 'artikel/:id', component: AgendaItemDetailComponent },
  { path: 'nieuws', component: NewsComponent },
  { path: 'verzoekje', component: VerzoekjeComponent },
  { path: 'shows', component: ShowsComponent },
  { path: 'shows/:id', component: ShowComponent },
  { path: 'staff', component: StaffComponent },
  { path: 'staff/:id', component: StaffMemberComponent },
  { path: 'live', component: LiveComponent },
  { path: 'beheer.php', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'adverteren', component: AdverterenComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'programmas', component: ProgrammasComponent },
  { path: 'medewerkers', component: MedewerkersComponent },

  { path: 'beheer', component: BeheerComponent },
  { path: 'beheer/article/:id', component: ArticleComponent },
  { path: 'beheer/blocks', component: AdmBlocksComponent },
  { path: 'beheer/blocks/:id', component: AdmBlockComponent },
  { path: 'beheer/profile', component: ChangepasswordComponent },
  { path: 'beheer/programming', component: AdmProgrammingComponent },
  { path: 'beheer/shows', component: AdmShowsComponent },
  { path: 'beheer/shows/:id', component: AdmShowComponent },
  { path: 'beheer/staff', component: AdmStaffComponent },
  { path: 'beheer/users', component: AdmUsersComponent },
  { path: 'beheer/users/:id', component: AdmUserComponent },
  { path: 'beheer/staff/:id', component: AdmStaffmemberComponent },
  { path: 'beheer/article/:id', component: ArticleComponent },
  { path: 'beheer/programming/:day/:hour', component: ProgramminghourComponent },
  { path: 'beheer/statistics', component: StatisticsComponent },
  { path: 'beheer/statistics/:id', component: StatisticComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
