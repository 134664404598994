import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../user.service';

import { AgendaItem } from '../data/agendaitem';
import {AgendaItemService} from '../agenda-item.service';


@Component({
  selector: 'app-agenda-item-detail',
  templateUrl: './agenda-item-detail.component.html',
  styleUrls: ['./agenda-item-detail.component.css']
})
export class AgendaItemDetailComponent implements OnInit {
  @Input() agendaItem: AgendaItem;
  isLoggedIn: boolean;
  isAdmin: boolean;

  constructor(
    private route: ActivatedRoute,
    private agendaItemService: AgendaItemService,
    private location: Location,
    private userService: UserService
  ) {

    this.route.params.subscribe(params => {
      this.getAgendaitem();
    });

  }

  ngOnInit() : void {
    this.getAgendaitem();
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.isAdmin = this.userService.isAdminUser();
  }
  getAgendaitem(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.agendaItemService.getAgendaItem(id)
      .subscribe(agendaitem => this.agendaItem = agendaitem);
  }
  goBack(): void {
    this.location.back();
  }

}
