import { Component, OnInit } from '@angular/core';
import { NOWPLAYING } from '../mock-nowplaying';
import { NowPlaying } from '../data/nowplaying';
import { NowPlayingService } from '../nowplaying.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  nowplaying: NowPlaying[];
  streamUrl: string;

  date = new Date();

  switchTime = new Date('01/08/2022 5:00:00 PM');

  constructor(private nowPlayingService: NowPlayingService) { }

  imageUrls = [
    '/images/header/header1.jpg',
    '/images/header/header2.jpg',
    '/images/header/header3.jpg',
    '/images/header/header4a.jpg',
    '/images/header/header6.jpg',
    '/images/header/header7.jpg',
    '/images/header/header8a.jpg',
    '/images/header/header9.jpg',
    '/images/header/header10.jpg'
  ];
  height: '250px';
  minHeight: string;
  arrowSize: '30px';
  showArrows: true;
  disableSwiping: false;
  autoPlay: true;
  autoPlayInterval: 3333;
  stopAutoPlayOnSlide: true;
  debug: true;
  backgroundSize: 'cover';
  backgroundPosition: 'center center';
  backgroundRepeat: 'no-repeat';
  showDots: true;
  dotColor: '#FFF';
  showCaptions: true;
  captionColor: '#FFF';
  captionBackground: 'rgba(0, 0, 0, .35)';
  lazyLoad: false;
  width: '100%';

  ngOnInit() : void {
    this.shuffleArray(this.imageUrls);
    this.getNowPlaying();
    this.streamUrl = environment.shoutCastServerUrl;
  }
  getNowPlaying(): void {
    this.nowPlayingService.getNowPlaying().subscribe(nowplaying => this.nowplaying = nowplaying);
  }

  shuffleArray = function (array) {
    let m = array.length, t, i;

    // While there remain elements to shuffle
    while (m) {
      // Pick a remaining element…
      i = Math.floor(Math.random() * m--);

      // And swap it with the current element.
      t = array[m];
      array[m] = array[i];
      array[i] = t;
    }
    return array;
  };

  toggleAudio(playerId, buttonId) {
    const player = (<HTMLAudioElement>document.getElementById(playerId));
    const button = <HTMLSpanElement>document.getElementById(buttonId);
    if (player.paused) {
      player.play();
      button.innerHTML = '<i class="fas fa-pause"></i>';
    } else {
      player.pause();
      button.innerHTML = '<i class="fas fa-play"></i>';
    }
  }



}
export interface IImage {
  url: string | null;
  href?: string;
  clickAction?: Function;
  caption?: string;
  title?: string;
}
