import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';
import { AdmBlock, AdmShowList } from '../adm-block';
import { AdmBlockService } from '../adm-block.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-adm-blocks',
  templateUrl: './adm-blocks.component.html',
  styleUrls: ['./adm-blocks.component.css']
})
export class AdmBlocksComponent implements OnInit {

  blocks: AdmBlock[];
  block: AdmBlock;
  shows: AdmShowList[];

  isLoggedIn: boolean;

  constructor(
    private currentUser: UserService,
    private messageService: MessageService,
    private router: Router,
    private blockService: AdmBlockService,
    private location: Location
  ) {
    this.block = new AdmBlock;
  }

  ngOnInit() : void {
    this.isLoggedIn = this.currentUser.isUserLoggedIn();
    // this.checkUser();
    this.getShows();

    this.getBlocks();
  }
  getShows(): void {
    this.blockService.getShowList().subscribe(shows => this.shows = shows);
  }

  getBlocks(): void {
    this.blockService.getBlocks().subscribe(blocks => this.blocks = blocks);
  }
  newBlock(): void {
    this.blockService.newBlock(this.block).subscribe(() => this.goTo('/beheer/blocks'));
  }

  checkUser() {
    if (!this.isLoggedIn) {
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }
  private log(message: string) {
    this.messageService.add(`BeheerBlocksComponent: ${message}`);
  }
  goBack(): void {
    this.location.back();
  }
  goTo(url) {
    this.router.navigateByUrl(url);
  }

}
