<div class='shows'>
<table cellspacing=1 cellpadding=3>
  <tbody>
  <tr>
    <th>Tijd</th>
    <th>Maandag</th>
    <th>Dinsdag</th>
    <th>Woensdag</th>
    <th>Donderdag</th>
    <th>Vrijdag</th>
    <th>Zaterdag</th>
    <th>Zondag</th>
  </tr>

  <tr>
    <td>
      <table>
        <tr *ngFor=" let hour of schedule.weekdays[0].hours">
          <td>{{hour.hourNumber}}:00</td>
        </tr>
      </table>
    </td>
    <td *ngFor="let weekday of schedule.weekdays">
      <table>
        <tr *ngFor=" let hour of weekday.hours">
          <td>{{programName(hour.programId)}}</td>
        </tr>
      </table>
    </td>

  </tr>

  </tbody>
</table>
</div>
