import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {User} from './data/user';
import {MessageService} from './message.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, tap} from 'rxjs/operators';
import {environment} from '../environments/environment';
import {UserInfo} from './admin/userInfo';

const httpOptions = {
  headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private loginUrl = environment.backendUrl + 'login';  // URL to web api
  // private loginUrl = 'http://localhost:8083/login.php';  // URL to web api

  constructor(
    private http: HttpClient,
    private messageService: MessageService) {
  }

  signIn(user: User): Observable<any> {
    // this.log('doing something');
    return this.http.post<UserInfo>(this.loginUrl, user, httpOptions).pipe(
      tap(userinfo => this.log('signin data: ' + userinfo.userName)),
      catchError(this.handleError<any>('signIn'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`LoginService: ${message}`);
  }
}
