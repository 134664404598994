import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';
import { AdmUser } from '../adm-user';
import { AdmUserService} from '../adm-user.service';

@Component({
  selector: 'app-adm-users',
  templateUrl: './adm-users.component.html',
  styleUrls: ['./adm-users.component.css']
})
export class AdmUsersComponent implements OnInit {
  newUser:AdmUser;
  isLoggedIn:boolean;
  users:AdmUser[];

  constructor(
    private userService:UserService,
    private messageService: MessageService,
    private router: Router,
    private admUserService: AdmUserService
  ) {
    this.newUser=new AdmUser;
    this.newUser.enabled=true;
  }

  ngOnInit() : void {
    this.isLoggedIn=this.userService.isUserLoggedIn();
    //this.checkUser();
    this.getUsers();
  }

  getUsers():void{
    this.admUserService.getUsers().subscribe(users => this.users = users);
  }

  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }

  addUser(){
    this.userService.newUser(this.newUser).subscribe(user => this.goTo('/beheer/user/'+user.id));
  }

  goTo(url){
    this.router.navigateByUrl(url);
  }

  private log(message: string) {
    this.messageService.add(`BeheerUsersComponent: ${message}`);
  }

}
