import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';
import { Show } from '../../data/show';
import { ShowService} from '../../show.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-adm-shows',
  templateUrl: './adm-shows.component.html',
  styleUrls: ['./adm-shows.component.css']
})
export class AdmShowsComponent implements OnInit {

  isLoggedIn:boolean;
  shows:Show[];
  show:Show;

  constructor(
    private currentUser:UserService,
    private messageService: MessageService,
    private router: Router,
    private showService: ShowService,
    private location:Location
  ) {
    this.show=new Show;
   }

  ngOnInit() : void {
    this.isLoggedIn=this.currentUser.isUserLoggedIn();
    //this.checkUser();
    this.getShows();
  }
  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }

  getShows():void{
    this.showService.getShows().subscribe(shows => this.shows = shows);
  }
  newShow(): void {
    this.showService.newShow(this.show).subscribe(show => this.goTo('/beheer/shows/'+show.id));
  }

  private log(message: string) {
    this.messageService.add(`BeheerShowsComponent: ${message}`);
  }
  goBack(): void {
    this.location.back();
  }
  goTo(url){
    this.router.navigateByUrl(url);
  }

}
