<div class='admin'>
  <app-beheer-nav></app-beheer-nav>

  <div *ngIf="newPassword">      

        <h3>
            Wachtwoord aanpassen
        </h3>
        <table>
          <tr>
            <td>Gebruikersnaam</td>
            <td>{{newPassword.username}}</td>
          </tr>
          <tr>
              <td>Oud Wachtwoord</td>
              <td><input [(ngModel)]="newPassword.oldPassword" style='width: 300px;' placeholder="wachtwoord" type='password'/></td>
            </tr>
          <tr>
              <td>Nieuw Wachtwoord</td>
              <td><input [(ngModel)]="newPassword.newPassword" style='width: 300px;' placeholder="wachtwoord" type='password'/></td>
            </tr>
            <tr>
                <td></td>
                <td> <button (click)="savepass()">Update Username/Password</button></td>
              </tr>
      </table>
     

  </div>
  
</div>