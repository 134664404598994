<div class='admin'>
    <app-beheer-nav></app-beheer-nav>

    <div class='adminpanel' *ngIf='block'>
    <h2>Programma blok: {{block.description}}</h2>
    <table cellpadding=5px>
      <tr>
        <td>Programma</td>
        <td>
            <select [(ngModel)]="block.showId">
              <option *ngFor="let s of shows" value='{{s.id}}' [innerHTML]="s.name|sanitizeHtml"></option>
            </select>
        </td>
      </tr>
      <tr>
        <td>Beschrijving</td>
        <td><input [(ngModel)]="block.description" style='width: 300px;' placeholder="Omschrijving"/></td>
      </tr>
      <tr>
        <td>Air-dag</td>
        <td> 
          <select [(ngModel)]='block.airweekday'>
            <option *ngFor="let o of options" value='{{o.value}}'> 
                {{o.name}}
              </option>
          </select>
        </td>
      </tr>
      <tr>
        <td>Air-Uur</td>
        <td>
          <select [(ngModel)]='block.airhour'>
            <option *ngFor="let h of hours" value='{{h.value}}'> 
                {{h.name}}
              </option>
          </select>
        </td>
      </tr>
    </table>
    <button (click)="save()">Opslaan</button>
    <button (click)="delete()">Delete</button>

  </div>

  </div>