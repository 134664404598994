import { Component } from '@angular/core';
import { faCoffee } from '@fortawesome/fontawesome-pro';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'LFM';
  date = new Date();

  switchTime = new Date('01/08/2022 5:00:00 PM');
  // switchTime = new Date('01/02/2022 5:39:00 PM');

}
