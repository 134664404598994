import { Component, OnInit } from '@angular/core';
import { AgendaItem } from '../data/agendaitem';
import {AgendaItemService} from '../agenda-item.service';

@Component({
  selector: 'app-upcomingcalendar',
  templateUrl: './upcomingcalendar.component.html',
  styleUrls: ['./upcomingcalendar.component.css']
})
export class UpcomingcalendarComponent implements OnInit {
  agendaItems:AgendaItem[];
  date = new Date();
  showMail = false;
  mailtext = 'studio%l-fm*nl';

  constructor(private agendaItemService: AgendaItemService) { }

  ngOnInit() : void {
    this.getAgendaItems();
  }
  getAgendaItems():void{
    this.agendaItemService.getUpcomingAgendaItems().subscribe(agendaitems => this.agendaItems = agendaitems);
  }
  getEpochDate(d){
    return new Date(d*1000);
  }
  toggleMail(){
    this.mailtext.replace('%', '@');
    this.mailtext.replace('*', '.');
    this.showMail=true;
  }


}
