<div class='shows'>
  <h1><span>Programmering</span>
    <span *ngIf="isAllowedToSeeHeatmap" id="heatmapSwitchContainer">
      <label for="showHeatmap" class="heatMapSwitch">Show Heatmap</label>
      <!-- Rounded switch -->
      <label class="switch">
        <input type="checkbox" name="showHeatmap" id="showHeatmap" [(ngModel)]="showHeatmap">
        <span class="slider round"></span>
      </label>
      <div *ngIf="showHeatmap" id="heatmapLegendContainer">
        <span class="heatmapLegendNumber">gemiddeld over de afgelopen 28 dagen</span>
        <span class="heatmapLegendNumber">0</span>
        <span id="heatmapLegend"></span>
        <span class="heatmapLegendNumber">{{maxValueInWeekdayStatisticsPerHour}}</span>
      </div>
    </span>
  </h1>
  <table cellspacing=1 cellpadding=3>
    <tbody>
      <tr>
        <th width=90>Tijd</th>
        <th>Maandag</th>
        <th>Dinsdag</th>
        <th>Woensdag</th>
        <th>Donderdag</th>
        <th>Vrijdag</th>
        <th>Zaterdag</th>
        <th>Zondag</th>
      </tr>
        <tr *ngFor="let hour of hours|slice:8" class="hourRow">
          <td class='hours'>{{hour.hour}}.00 - {{hour.hour+1}}.00</td>
          <td *ngFor="let weekday of hour.weekdays"
              [ngClass]="weekday.automation ? 'automation':(weekday.live ? 'live': 'normal')"
              [class.currenHour]="isCurrentHourAndDay(hour.hour, weekday.weekday)"
              [style.background]="calculateBackgroundColorForHeatMap(hour.hour, weekday.weekday, weekday.automation, weekday.live)"
              [title]="getToolTipForHeatMap(hour.hour, weekday.weekday)">
            <a routerLink="/shows/{{weekday.showId}}" [innerHTML]="weekday.showName|sanitizeHtml"></a>
          </td>
        </tr>
    </tbody>
  </table>
<!--  <div class='legenda'>
    <table>
      <tr><td><b>Legenda: </b></td><td class='live'>Live Show</td><td class='normal'>Herhaling</td><td class='automation'>Non-Stop</td></tr>
    </table>
  </div>-->
</div>
