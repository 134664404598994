<div class='admin'>
    <app-beheer-nav></app-beheer-nav>
    <div class='adminpanel' *ngIf='users'>
    <h2>Gebruikers</h2>
    <div *ngFor="let user of users">
      <a routerLink="/beheer/users/{{user.id}}"> {{user.username}} </a>
    </div>
  </div>
  <h3>Nieuwe gebruiker</h3>
  <table>
    <tr><td>Gebruikersnaam</td><td><input [(ngModel)]="newUser.username" style='width: 300px;' placeholder="Naam nieuwe gebruiker"/></td></tr>
    <tr><td>Wachtwoord</td><td><input [(ngModel)]="newUser.password" style='width: 300px;' placeholder="Wachtwoord" type="password"/></td></tr>
    <tr><td>Email</td><td><input [(ngModel)]="newUser.email" style='width: 300px;' placeholder="email"/></td></tr>
    <tr><td>Voornaam</td><td><input [(ngModel)]="newUser.firstname" style='width: 300px;' placeholder="Voornaam"/></td></tr>
    <tr><td>Achternaam</td><td><input [(ngModel)]="newUser.lastname" style='width: 300px;' placeholder="Achternaam"/></td></tr>
  </table>
  <button (click)="addUser()">Gebruiker Aanmaken</button>

</div>