
<h1>Agenda</h1>
<div class='calendar'>
  <h2 *ngFor="let agendaitem of agendaItems">
    <label>{{agendaitem.expires|date:"d-MMMM-yyyy"}}</label>
    <a routerLink="/artikel/{{agendaitem.id}}"> {{agendaitem.title}} </a>
  </h2>
  <h2><a routerLink="/agenda">Bekijk alle evenementen</a></h2>
  Wil je jouw evenement ook vermelden, neem dan even contact met ons op.
  <span (click)="toggleMail()" *ngIf="!showMail">[Email adres weergeven]</span>
  <span  *ngIf="showMail">U kunt een email sturen naar studio@l-fm.nl</span>

</div>


