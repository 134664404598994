import { Component, OnInit } from '@angular/core';
import { User } from '../data/user';
import { LoginService }  from '../login.service';
import { Location } from '@angular/common';
import { UserService } from '../user.service';
import { MessageService } from '../message.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  isLoggedIn: boolean;

  constructor(
    private loginService: LoginService,
    private location: Location,
    private currentUser: UserService,
    private messageService: MessageService,
    private router: Router
  ) { }

  ngOnInit() : void {
    this.isLoggedIn = this.currentUser.isUserLoggedIn();
    this.logOut();
  }

  logOut(): void {
    this.currentUser.logout();
    this.log(`UserLoginStatus: ` + this.currentUser.isUserLoggedIn());
    this.router.navigateByUrl('/home');

  }
  private log(message: string) {
    this.messageService.add(`LoginComponent: ${message}`);
  }

}
