import {Component, OnInit, OnChanges, OnDestroy} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location} from '@angular/common';
import { UserService } from '../../user.service';
import { StatisticService } from '../statistic.service';
import { Statistic } from '../statistic';
import {interval} from 'rxjs/internal/observable/interval';
import {startWith, switchMap} from 'rxjs/operators';
import {Competition} from '../../data/competition';
import {AzuraCastUpdate} from '../../data/azuraCast';

//#region Method witch is defined in src/assets/js/google.charts.js
declare function drawChart(controlConfig);
declare function drawChartWithControl(containerElement, data, controlConfig, chartConfig);
//

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent implements OnInit, OnChanges, OnDestroy {
  statistics: Statistic[];
  isLoggedIn: boolean;
  isAdmin: boolean;
  currentListeners: string;
  calendarHeaderHeight = 20;
  calendarBottomHeight = 30;
  calendarYearHeight = 7 * 15 + this.calendarBottomHeight;
  calendarHeight = 100;
  listenersPerMinDays = 7;
  competition: Competition;
  mfm = 0;
  private currentListenerRefreshInterval;

  constructor(
    private route: ActivatedRoute,
    private statisticService: StatisticService,
    private location: Location,
    private userService: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isLoggedIn = this.userService.isUserLoggedIn();
    this.checkUser();
    this.isAdmin = this.userService.isAdminUser();
    this.currentListeners = '?';
    this.initCharts();
    this.testExperiment();

    this.currentListenerRefreshInterval = setInterval(() => {
      this.statisticService.getCurrentListeners().subscribe(currentListeners => this.currentListeners = currentListeners);
      this.statisticService.getCompetition().subscribe(competition => this.competition = competition);


    },30000) ;

  }

  ngOnChanges(): void {
    // console.log('ngOnChanges');
  }

  ngOnDestroy(): void {
    if (this.currentListenerRefreshInterval) {
      clearInterval(this.currentListenerRefreshInterval);
    }
  }

  private initCharts(): void {
    this.statisticService.getCurrentListeners().subscribe(currentListeners => this.currentListeners = currentListeners)
    this.initAverageListenersPerHourLastMonth();
    this.initListenersOverLastTwoDays();
    this.initCalendarChart();
  }

  testExperiment(){
    this.statisticService.getServerSentEvent('https://liveradio.dtvstreams.nl/api/live/nowplaying/sse?cf_connect=%7B%22subs%22%3A%7B%22station%3Amfm%22%3A%7B%22recover%22%3Atrue%7D%7D%7D')
      .subscribe((message) => {
        const data: AzuraCastUpdate = JSON.parse(message.data);


        if(data.connect){
          this.mfm = null;
        }
        if(data.channel && data.pub?.data?.np?.listeners?.current){
          this.mfm = data.pub.data.np.listeners.current;
        }
      });
  }


  private initAverageListenersPerHourLastMonth(): void {
    const columnNames = ['Uur van de dag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag', 'Zondag'];
    const chartConfig = {
      chartType: 'LineChart',
      containerId: 'averageListenersPerHourLastMonth_chart_div',
      dataTable: null,
      options: {
        width: '100%',
        height: 500,
        legend: 'rechts',
        crosshair: { trigger: 'both' }, // Display crosshairs on focus and selection
        chartArea: {left: 50, width: '79%', bottom: 60, top: 20},
        vAxis: {
          maxValue: 10,
          gridlines: {
            count: 10
          },
          viewWindow: {
            min: 0
          }
        },
        hAxis: {
          gridlines: {
            count: 24
          }
        }
      }
    };

    this.statisticService.getAverageListenersPerHourLastMonth().subscribe(data => {
      if (data === null) {
        data = [columnNames];
      } else if (Array.isArray(data)) {
        data.unshift(columnNames);
      }
      chartConfig.dataTable = data;
      // Draw the chart.
      drawChart(chartConfig);
    });
  }

  // Callback that creates and populates a data table,
  // instantiates a dashboard, a range slider and a pie chart,
  // passes in the data and draws it.
  private initListenersOverLastTwoDays(): void {
    const columnDefinitions = [
      {
        label: 'Minuut',
        id: 'minute',
        type: 'datetime'},
      {
        label: 'Luisteraars',
        id: 'listeners',
        type: 'number'}
    ];

    const now = new Date();
    const beginOfToday = new Date();
    beginOfToday.setTime(beginOfToday.getTime()
      - beginOfToday.getHours() * 3600000
      - beginOfToday.getMinutes() * 60000
      - beginOfToday.getSeconds() * 1000);

    const controlConfig = {
      controlType: 'ChartRangeFilter',
      containerId: 'listenersOverLastXDays_filter_div',
      options: {
        filterColumnIndex: 0,
        ui: {
          chartOptions: {
            height: 100,
            width: '100%',
            chartArea: {
              width: '95%',
              height: '80%'
            },
            hAxis: {
              gridlines: {
                count: -1,
                units: {
                  days: {format: ['E d', 'E']},
                  hours: {format: ['HH:mm']},
                  minutes: {format: ['HH:mm']}
                }
              },
              minorGridlines: {
                units: {
                  hours: {format: ['HH:mm']},
                  minutes: {format: ['HH:mm']}
                }
              }
            },
            vAxis: {
              viewWindow: {
                min: 0
              }
            }
          },
          chartView: {
            columns: [0, 1]
          }
        }
      },
      state: {
        range: {
          start: beginOfToday
        }
      }
    };

    const chartConfig = {
      chartType: 'LineChart',
      containerId: 'listenersOverLastXDays_chart_div',
      options: {
        width: '100%',
        height: 500,
        legend: 'none',
        crosshair: { trigger: 'both' },
        chartArea: {left: 50, width: '92%', bottom: 50, top: 20},
        vAxis: {
          gridlines: {
            count: 10
          },
          viewWindow: {
            min: 0
          }
        },
        hAxis: {
          gridlines: {
            count: -1,
            units: {
              days: {format: ['E d', 'E']},
              hours: {format: ['HH:mm']},
              minutes: {format: ['HH:mm']}
            }
          },
          minorGridlines: {
            units: {
              hours: {format: ['HH:mm']},
              minutes: {format: ['HH:mm']}
            }
          }
        }
      }
    };

    this.statisticService.getListenersOverLastXDays(this.listenersPerMinDays).subscribe(data => {
      console.log('getListenersOverLastXDays().subscribe() -> raw data:');
      console.log(data);

      if (data === null) {
        data = [columnDefinitions];
      } else if (Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          if (Array.isArray(data[i]) && data[i].length > 1) {
            if (typeof data[i][0] === 'object' && data[i][0].hasOwnProperty('v')) {
              // data[i][0].v = Date.parse(data[i][0].v);
              if (!this.isDate(data[i][0].v)) {
                data[i][0] = new Date(data[i][0].v);
              }
            } else {
              if (!this.isDate(data[i][0])) {
                data[i][0] = new Date(data[i][0]);
              }
            }
          }
        }
        data.unshift(columnDefinitions);
      }

      console.log('getListenersOverLastXDays().subscribe() -> processed data:');
      console.log(data);

      // Draw the dashboard.
      drawChartWithControl('listenersOverLastXDays_dashboard_div', data, controlConfig, chartConfig);
    });
  }

  private isDate(x): boolean {
    return (null != x) && !isNaN(x) && ('undefined' !== typeof x.getDate);
  }

  private initCalendarChart(): void {
    const columnDefinitions = [
      {
        label: 'Dag',
        id: 'day',
        type: 'date'},
      {
        label: 'Luisteraars',
        id: 'listeners',
        type: 'number'}
    ];

    const chartConfigAvg = {
      chartType: 'Calendar',
      containerId: 'avgListenerCalendar_div',
      dataTable: null,
      height: 150,
      options: {
        title: 'Gemiddeld aantal luisteraars per dag',
        calendar: {
          cellSize: 15,
          monthLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          yearLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          dayOfWeekLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          dayOfWeekRightSpace: 10,
          daysOfWeek: 'SMDWDFS',
        }
      }
    };

    const chartConfigMin = {
      chartType: 'Calendar',
      containerId: 'minListenerCalendar_div',
      dataTable: null,
      height: 150,
      options: {
        title: 'Min aantal luisteraars per dag',
        calendar: {
          cellSize: 15,
          monthLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          yearLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          dayOfWeekLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          dayOfWeekRightSpace: 10,
          daysOfWeek: 'SMDWDFS',
        }
      }
    };

    const chartConfigMax = {
      chartType: 'Calendar',
      containerId: 'maxListenerCalendar_div',
      dataTable: null,
      height: 150,
      options: {
        title: 'Max aantal luisteraars per dag',
        calendar: {
          cellSize: 15,
          monthLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          yearLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          dayOfWeekLabel: {
            fontName: 'Alegreya Sans',
            color: '#000000',
            bold: true
          },
          dayOfWeekRightSpace: 10,
          daysOfWeek: 'SMDWDFS',
        }
      }
    };

    this.statisticService.getListenersPerDayCalendar().subscribe(data => {
      const minData = [columnDefinitions];
      const avgData = [columnDefinitions];
      const maxData = [columnDefinitions];

/*      console.log('getListenersPerDayCalendar().subscribe() -> raw data:');
      console.log(data);*/

      if (data !== null && Array.isArray(data)) {
        for (let i = 0; i < data.length; i++) {
          if (Array.isArray(data[i]) && data[i].length > 1) {
            if (typeof data[i][0] === 'object' && data[i][0].hasOwnProperty('v')) {
              data[i][0] = new Date(data[i][0].v);
            } else {
              data[i][0] = new Date(data[i][0]);
            }
          }
          if (data[i].length > 1) {
            // data[i] = data[i].slice(0, 2);
            avgData.push([data[i][0], data[i][1]]);
          }
          if (data[i].length > 2) {
            minData.push([data[i][0], data[i][2]]);
          }
          if (data[i].length > 3) {
            maxData.push([data[i][0], data[i][3]]);
          }
        }
      }
/*      console.log('getListenersPerDayCalendar().subscribe() -> processed data:');
      console.log(data);*/

      const startYear = data[0][0].getFullYear();
      const endYear = data[data.length - 1][0].getFullYear();
      // this.calendarHeightUpdated = false;
      this.calendarHeight = this.calendarHeaderHeight + ((endYear - startYear + 1) * this.calendarYearHeight) + this.calendarBottomHeight;
/*      console.log('startYear: ' + startYear + ' data[0][0]: ' + data[0][0]);
      console.log('endYear: ' + endYear + ' data[data.length - 1][0]: ' + data[data.length - 1][0]);
      console.log('calendarHeight: ' + this.calendarHeight);*/

      chartConfigAvg.height = this.calendarHeight;
      chartConfigMin.height = this.calendarHeight;
      chartConfigMax.height = this.calendarHeight;
      chartConfigAvg.dataTable = avgData;
      chartConfigMin.dataTable = minData;
      chartConfigMax.dataTable = maxData;
      // Draw the chart.
      //drawChart(chartConfigAvg);
      //drawChart(chartConfigMin);
      drawChart(chartConfigMax);
    });
  }

  checkUser(): void {
    if (!this.isLoggedIn) {
      console.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/login');
    }
  }

}
