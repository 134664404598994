<app-programming></app-programming>
<div class='shows'>
  <h1>De Programma's van L-FM</h1>
  <div *ngFor="let show of shows">
    <div class='show'>
      <div class='imagecontainer'>
        <picture *ngIf="show.hasPicture">
          <source srcset="/images/photos/{{show.id}}.show.webp" type="image/webp">
          <source srcset="/images/photos/{{show.id}}.show.png" type="image/png">
          <img src='/images/photos/{{show.id}}.show.jpg' alt="{{show.name}}">
        </picture>

      </div>
        <h2><a routerLink="/shows/{{show.id}}" [innerHTML]="show.name|sanitizeHtml"></a></h2>
        <p><span [innerHTML]="show.description|slice:0:300|sanitizeHtml"></span><span>... <a routerLink="/shows/{{show.id}}">[Lees Meer]</a></span></p>
      </div>
  </div>
</div>
