import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';

import { AdmUser } from '../adm-user';
import { AdmUserService }  from '../adm-user.service';
import {CurrentUser} from '../currentUser';
import {Password} from '../password';
import {Roles} from '../roles';

@Component({
  selector: 'app-adm-user',
  templateUrl: './adm-user.component.html',
  styleUrls: ['./adm-user.component.css']
})
export class AdmUserComponent implements OnInit {
  @Input() user: AdmUser;
  isLoggedIn:boolean;
  newPassword: Password;
  currentUser: CurrentUser;
  roles:Roles[];
  userRoles: string[];

  constructor(
    private route: ActivatedRoute,
    private admUserService: AdmUserService,
    private location: Location,
    private userService:UserService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit() : void {
    this.newPassword=new Password;
    this.getUser();
    this.getRoles();
  }

  getUser():void{
    const id = +this.route.snapshot.paramMap.get('id');
    this.admUserService.getUser(id)
      .subscribe(user =>{
         this.user = user;
         this.newPassword.userId=user.id;
         this.newPassword.username=user.username;
         this.userRoles=user.roleIds;
        });
  }
  getRoles():void{
    this.userService.getRoles().subscribe(roles => this.roles = roles);
  }
  save(): void {
    this.admUserService.save(this.user)
      .subscribe(() => this.goTo('/beheer/users'));
  }
  savepass(): void {
    this.userService.newPassword(this.newPassword)
      .subscribe(() => this.goTo('/beheer/users'));
  }
  delete(): void {
    this.admUserService.delete(this.user)
      .subscribe(() => this.goTo('/beheer/users'));
  }
  goBack(){
    this.router.navigateByUrl('/beheer/users');
  }
  goTo(url){
    this.router.navigateByUrl(url);
  }
  checkUser(){
    if(!this.isLoggedIn){
      console.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }
  addRole(roleId){
    this.user.roleIds.push(roleId);
    this.admUserService.save(this.user)
      .subscribe(() => this.goTo('/beheer/users'));

  }
 removeRole(roleId){
   let index=this.user.roleIds.indexOf(roleId);
   this.user.roleIds.splice(index, 1);
   this.admUserService.save(this.user)
      .subscribe(() => this.goTo('/beheer/users'));
  }




}
