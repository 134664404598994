import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';

import { StaffMember } from '../../data/staffmember';
import { StaffmemberService }  from '../../staffmember.service';

@Component({
  selector: 'app-adm-staffmember',
  templateUrl: './adm-staffmember.component.html',
  styleUrls: ['./adm-staffmember.component.css']
})
export class AdmStaffmemberComponent implements OnInit {
  @Input() staffmember: StaffMember;
  isLoggedIn:boolean;

  constructor(
    private route: ActivatedRoute,
    private staffmemberService: StaffmemberService,
    private location: Location,
    private currentUser:UserService,
    private messageService: MessageService,
    private router: Router,
  ) { }

  ngOnInit() : void {
    this.getStaffmember();
    //this.checkUser();
  }
  getStaffmember():void{
    const id = +this.route.snapshot.paramMap.get('id');
    this.staffmemberService.getStaffmember(id)
      .subscribe(staffmember => this.staffmember = staffmember);
  }
  save(): void {
    this.staffmemberService.updateStaffmember(this.staffmember)
      .subscribe(() => this.goTo('/beheer/staff'));
  }
  delete(): void {
    this.staffmemberService.deleteStaffmember(this.staffmember)
      .subscribe(() => this.goTo('/beheer/staff'));
  }
  goBack(){
    this.router.navigateByUrl('/beheer');
  }
  goTo(url){
    this.router.navigateByUrl(url);
  }
  checkUser(){
    if(!this.isLoggedIn){
      console.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }


}
