import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-beheer-nav',
  templateUrl: './beheer-nav.component.html',
  styleUrls: ['./beheer-nav.component.css']
})
export class BeheerNavComponent implements OnInit {
isAdmin=false;

  constructor(
    private userService: UserService,
    private router: Router,
  ) { }

  ngOnInit() : void {
    this.isAdmin=this.userService.isAdmin;
  }

  logOut(): void {
    this.userService.manualLogout();
    console.log('Logged out');
    this.router.navigate(['/login']);

  }

}
