<nav>
  <a *ngIf="isAdmin" routerLink="/beheer/programming">Programmering |</a>
  <a *ngIf="isAdmin" routerLink="/beheer/shows">Programmas |</a>
  <a *ngIf="isAdmin"  routerLink="/beheer/staff">Medewerkers |</a>
  <a *ngIf="isAdmin"  routerLink="/beheer/blocks">Programma Blokken |</a>
  <a *ngIf="isAdmin"  routerLink="/beheer/users">Gebruikers |</a>
  <a *ngIf="isAdmin"  routerLink="/beheer/statistics">Luisteraars |</a>
  <a routerLink="/beheer/profile">Wachtwoord Wijzigen |</a> 
  <a (click)="logOut()"><i class="far fa-sign-out" ></i> Uitloggen</a>
</nav>
