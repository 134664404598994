export class WeekdayStatisticPerHour {
  hourOfDay: number;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;

  constructor(hourOfDay: number, monday: number, tuesday: number, wednesday: number,
              thursday: number, friday: number, saturday: number, sunday: number) {
    this.hourOfDay = hourOfDay;
    this.monday = monday;
    this.tuesday = tuesday;
    this.wednesday = wednesday;
    this.thursday = thursday;
    this.friday = friday;
    this.saturday = saturday;
    this.sunday = sunday;
  }

  public static from(s: WeekdayStatisticPerHour): WeekdayStatisticPerHour {
    return new WeekdayStatisticPerHour(s.hourOfDay, s.monday, s.tuesday, s.wednesday, s.thursday, s.friday, s.saturday, s.sunday);
  }

  public getValueForDay(weekday: number): number {
    switch (weekday) {
      case 1:
        return this.monday;
      case 2:
        return this.tuesday;
      case 3:
        return this.wednesday;
      case 4:
        return this.thursday;
      case 5:
        return this.friday;
      case 6:
        return this.saturday;
      case 7:
        return this.sunday;
      default:
        return null;
    }
  }

  public getMaxValue(): number {
    let maxValue = 0;
    if (this.monday > maxValue) {
      maxValue = this.monday;
    }
    if (this.tuesday > maxValue) {
      maxValue = this.tuesday;
    }
    if (this.wednesday > maxValue) {
      maxValue = this.wednesday;
    }
    if (this.thursday > maxValue) {
      maxValue = this.thursday;
    }
    if (this.friday > maxValue) {
      maxValue = this.friday;
    }
    if (this.saturday > maxValue) {
      maxValue = this.saturday;
    }
    if (this.sunday > maxValue) {
      maxValue = this.sunday;
    }
    return maxValue;
  }
}

