import { Component, OnInit } from '@angular/core';
import { UserService } from '../../user.service';
import { MessageService } from '../../message.service';
import { Router } from '@angular/router';
import { ScheduleService} from '../../schedule.service';
import { Hour } from '../../data/hour';

@Component({
  selector: 'app-adm-programming',
  templateUrl: './adm-programming.component.html',
  styleUrls: ['./adm-programming.component.css']
})
export class AdmProgrammingComponent implements OnInit {

  isLoggedIn:boolean;
  hours:Hour[];
  test: any[];

  constructor(
    private currentUser:UserService,
    private messageService: MessageService,
    private router: Router,
    private scheduleService: ScheduleService
  ) {
    this.test = [1,'A',true];
  }

  ngOnInit() : void {
    this.isLoggedIn=this.currentUser.isUserLoggedIn();
    this.getBlocks();
    //this.checkUser();
  }
  checkUser(){
    if(!this.isLoggedIn){
      this.log('You are not logged in, returning you home');
      this.router.navigateByUrl('/home');
    }
  }
  private log(message: string) {
    this.messageService.add(`BeheerComponent: ${message}`);
  }
  getBlocks():void{
    this.scheduleService.getBlocks().subscribe(hours => this.hours = hours);
  }

}

