import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import { map } from 'rxjs/operators';

import {TOKEN_AUTH_PASSWORD, TOKEN_AUTH_USERNAME} from './admin/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  // static AUTH_TOKEN = '/oauth/token';
  static AUTH_TOKEN = environment.backendUrl + 'oauth/token';  // URL to web api
  static TOKEN_AUTH_USERNAME = 'testlfmclientid';
  static TOKEN_AUTH_PASSWORD = 'ZkK9HEZB0CaMGPgg';

  constructor(private http: HttpClient, public jwtHelper: JwtHelperService) {
  }

  login(username: string, password: string) {
    // console.log("Expired before: "+this.jwtHelper.getTokenExpirationDate());
    const body = `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}&grant_type=password`;


    const httpHeaders = new HttpHeaders()
      .append('Content-Type', 'application/x-www-form-urlencoded')
      .append('Authorization', 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Basic ' + btoa(TOKEN_AUTH_USERNAME + ':' + TOKEN_AUTH_PASSWORD)
      })
    };

    // return null;
    // console.log("httpOptions = ", httpOptions);

    // console.log("Expired after: "+this.jwtHelper.isTokenExpired());
    return this.http.post(AuthenticationService.AUTH_TOKEN, body, httpOptions)
    // .map(res => res.json())
      .pipe(map((res: any) => {
        if (res.access_token) {
          return res.access_token;
        }
        return null;
      }));


  }

}
